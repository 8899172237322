import React from 'react';
import { Menu, MenuItem, Divider, IconButton } from '@mui/material';
import { WbSunny, NightsStay, SettingsSystemDaydream, DesktopWindows, Person, Description, ExitToApp } from '@mui/icons-material';


const ProfileMenu = ({openProfileMenu,setOpenProfileMenu,user,handleLogout}) => {

  const handleClose = () => {
    setOpenProfileMenu(!openProfileMenu);
  };
  return (
    <div className="relative">
      <Menu
        className='menu-profile-container'
        open={openProfileMenu}
        onClose={handleClose}
        sx={{ position: 'static' }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          className: 'w-72 rounded-md shadow-lg mt-2',
        }}
        BackdropProps={{
          onClick: (event) => event.stopPropagation(),
        }}
        ModalProps={{
          BackdropProps: {
            invisible: true
          },
          keepMounted: true,
          disableAutoFocus: true,
          disableEnforceFocus: true,
        }}
      >
        <div className="p-4">
          <h2 className="text-lg font-semibold text-gray-800">{user?.name}</h2>
          <p className="text-sm text-gray-500">{user?.email}</p>
        </div>
        <div className="flex justify-between px-4 pb-4">
          <IconButton className="w-10 h-10 text-gray-600 hover:bg-gray-100">
            <WbSunny className="w-5 h-5" />
          </IconButton>
          <IconButton className="w-10 h-10 text-gray-600 hover:bg-gray-100">
            <NightsStay className="w-5 h-5" />
          </IconButton>
          <IconButton className="w-10 h-10 text-gray-600 hover:bg-gray-100">
            <SettingsSystemDaydream className="w-5 h-5" />
          </IconButton>
          <IconButton className="w-10 h-10 text-gray-600 hover:bg-gray-100">
            <DesktopWindows className="w-5 h-5" />
          </IconButton>
        </div>
        <Divider />
        <MenuItem onClick={handleClose} className="px-4 py-2 hover:bg-gray-100">
          <Person className="w-5 h-5 mr-3 text-gray-600" />
          <span className="text-gray-700">Your profile</span>
        </MenuItem>
        <MenuItem onClick={handleClose} className="px-4 py-2 hover:bg-gray-100">
          <Description className="w-5 h-5 mr-3 text-gray-600" />
          <span className="text-gray-700">Terms & policies</span>
        </MenuItem>
        <MenuItem onClick={handleLogout} className="px-4 py-2 hover:bg-gray-100 text-red-500 hover:text-red-600">
          <ExitToApp className="w-5 h-5 mr-3" />
          <span>Log out</span>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default ProfileMenu;

