export const extractText = (content) => {
    let extractedText = "";
    
    if (Array.isArray(content)) {
      content.forEach((item) => {
        extractedText += extractText(item); // Recursive call for nested array or object
      });
    } else if (typeof content === "object" && content !== null) {
      if (content.text) {
        extractedText += content.text; // Add text if it exists
      }
      if (content.content) {
        extractedText += extractText(content.content); // Recursive call for nested content
      }
      if (content.type === "hardBreak") {
        extractedText += "\n"; // Add line break for hardBreak type
      }
    }
    
    return extractedText;
  };
  