import React, { memo, useEffect, useState, useCallback, useMemo, useRef } from "react";
import { SlidersHorizontal } from "lucide-react";
import { extractText } from "../Utils/utils.js";
import ShowChat from "./ShowChat";
import ChatInput from "./ChatInput";
import ChatDetailsDrawer from "./ChatSettingsComponent/ChatDetailsDrawer.jsx";
import ApiSource from "../api/ApiSource";
import "./ChatCreate.css";
import { globalConfig } from "../api/config.js";
import LeftSideChatListCanvas from "./LeftSideChatListCanvas.jsx";
import useNavStore from "../Store/useNavStore.js";
import { useParams } from "react-router-dom";
import ModelSelector from "./ModelSelector.jsx";

const useChatState = (selectedOrgNav) => {
  const [selectedChatId, setSelectedChatId] = useState(null);
  const [selectedChatTittle, setSelectedChatTittle] = useState('New chat');
  const [chatResponseData, setChatResponseData] = useState([]);
  const [isStreaming, setIsStreaming] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetchChatHistory = useCallback(async (chat_id) => {
    if (!chat_id) {
      setChatResponseData([]);
      setIsLoading(false);
      return false;
    }

    try {
      setIsLoading(true);
      const response = await ApiSource.getChatById(chat_id);
      const messages = response.body.messages || [];
      setChatResponseData(messages);
      setIsLoading(false);
      return true;
    } catch (error) {
      console.error("Error fetching chat history:", error);
      setChatResponseData([]);
      setIsLoading(false);
      return false;
    }
  }, []);

  const handleSelectChat = useCallback((chat_id,title) => {
    setSelectedChatId(chat_id);
    setSelectedChatTittle(title);
  }, []);

  useEffect(() => {
    let isMounted = true;

    const loadChatHistory = async () => {
      if (selectedChatId && isMounted) {
        await fetchChatHistory(selectedChatId);
      } else {
        setChatResponseData([]);
      }
    };

    loadChatHistory();
    return () => {
      isMounted = false;
    };
  }, [selectedChatId, fetchChatHistory]);

  // Reset chat state function
  const resetChatState = useCallback(() => {
    setChatResponseData([]);
    setIsStreaming(false);
    setSelectedChatId(null);
    setSelectedChatTittle('New chat');
  }, []);

  useEffect(() => {
    resetChatState();
  }, [selectedOrgNav, resetChatState]);

  // Update bot message function
  const updateBotMessage = useCallback((messageId, content, isError = false) => {
    setChatResponseData((prevMessages) =>
      prevMessages.map((message) =>
        message.id === messageId
          ? {
            ...message,
            content: isError
              ? content
              : (message.content || "") + content,
            isStreaming: !isError,
            role: "assistant",
            type: "message",
          }
          : message
      )
    );
  }, []);

  return {
    selectedChatId,
    chatResponseData,
    isStreaming,
    isLoading,
    setIsStreaming,
    handleSelectChat,
    resetChatState,
    updateBotMessage,
    setChatResponseData,
    selectedChatTittle,
  };
};

const useChatSubmission = (
  user_id,
  selectedOrgNav,
  selectedModelNav,
  selectedChatId,
  handleSelectChat,
  updateBotMessage,
  setChatResponseData,
  resetChatState,
  selectedChatSettings,
  setIsStreaming,
) => {
  const abortControllerRef = useRef(null);
  const userIdRef = useRef(user_id);
  const selectedOrgNavRef = useRef(selectedOrgNav);
  const selectedModelNavRef = useRef(selectedModelNav);
  const selectedChatIdRef = useRef(selectedChatId);
  const readerRef = useRef(null);

  const stopStreamConnection = useCallback(async () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    if (readerRef.current) {
      try {
        await readerRef.current.cancel();
      } catch (error) {
        console.log("Error canceling reader:", error);
      }
    }
  }, []);

  const onChatSubmitHandler = useCallback(async (formData) => {
    await stopStreamConnection();

    abortControllerRef.current = new AbortController();
    const signal = abortControllerRef.current.signal;

    const timestamp = new Date().toUTCString();
    const messageId = `bot-${timestamp}`;
    const content = extractText(formData.content);

    const newUserMessage = {
      id: timestamp,
      role: "user",
      content: content,
      createdAt: timestamp,
      model: selectedModelNavRef.current?.Name || 'gpt-4'
    };
    const newBotMessage = {
      id: messageId,
      role: "bot",
      content: "",
      isStreaming: true,
      createdAt: timestamp,
      model: selectedModelNavRef.current?.Name || 'gpt-4'
    };

    // Improved chat data handling
    setChatResponseData((prev) => {
      // Always add messages, create a new array if needed
      const updatedMessages = prev.length > 0 ? [...prev] : [];
      updatedMessages.push(newUserMessage);
      return updatedMessages;
    });
    try {
      const apiResponseData = await fetch(
        `${globalConfig.baseUrl}/chats`,
        {
          method: "POST",
          signal,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${globalConfig.accessToken}`,
          },
          body: JSON.stringify({
            message: content,
            user_id: userIdRef.current,
            organization_id: selectedOrgNavRef.current.ID,
            chat_id: selectedChatIdRef.current,
            model: selectedModelNavRef.current?.Name || 'gpt-4',
            chat_settings: {
              output_formats: selectedChatSettings?.outputFormat,
              tones: selectedChatSettings?.tones,
              languages: selectedChatSettings?.language,
              writing_styles: selectedChatSettings?.writingStyle
            }
          }),
        }
      );

      // Handle non-200 status codes
      if (!apiResponseData.ok) {
        setIsStreaming(false)
        throw new Error(`API request failed with status: ${apiResponseData.status}`);
      }

      if (!apiResponseData.body) {
        setIsStreaming(false)
        throw new Error("Streaming response not available");
      }

      readerRef.current = apiResponseData.body.getReader();
      const decoder = new TextDecoder();
      let buffer = "";

      // Add bot message to the conversation
      setChatResponseData((prev) => [...prev, newBotMessage]);

      // Process the streaming response
      while (true) {
        const { value, done } = await readerRef.current.read();
        if (done) break;
        if (signal.aborted) break;

        const chunk = decoder.decode(value, { stream: true });
        buffer += chunk;

        const messages = buffer.split("\n\n");
        buffer = messages.pop() || "";

        for (const message of messages) {
          if (!message.trim()) continue;

          const lines = message.split("\n");
          const dataLine = lines.find((line) => line.startsWith("data:"));

          if (!dataLine) continue;

          try {
            const dataJson = JSON.parse(dataLine.slice(5));
            const chatId = dataJson.chat_id;

            // Select and fetch chat history if a new chat is created
            if (chatId && !!selectedChatIdRef.current) {
              handleSelectChat(chatId);
            }

            if (dataJson.content !== undefined) {
              updateBotMessage(messageId, dataJson.content);
            }
          } catch (error) {
            console.warn("Failed to parse message:", error);
            updateBotMessage(messageId, "Error processing message", true);
          }
        }
      }
      setIsStreaming(false);
    } catch (error) {
      setIsStreaming(false);
      // If the request was aborted, just exit
      if (error.name === 'AbortError') {
        console.log("Request aborted");
        return;
      }

      updateBotMessage(messageId, "Something went wrong. Please try again later.", true);

      if (error.message.includes("API request failed")) {
        console.error("API request failed with status:", error.message);
      } else {
        console.error("Unexpected error:", error);
      }
    }
  }, [
    handleSelectChat,
    updateBotMessage,
    resetChatState,
    selectedChatSettings,
    stopStreamConnection
  ]);

  useEffect(() => {
    // Update refs when dependencies change
    userIdRef.current = user_id;
    selectedOrgNavRef.current = selectedOrgNav;
    selectedModelNavRef.current = selectedModelNav;
    selectedChatIdRef.current = selectedChatId;
  }, [user_id, selectedOrgNav, selectedModelNav, selectedChatId]);

  useEffect(() => {
    // Cleanup on unmount
    return () => {
      stopStreamConnection();
    };
  }, [stopStreamConnection]);

  return { onChatSubmitHandler, stopStreamConnection };
};

const ChatCreate = () => {
  const { id: user_id } = useParams();
  const { selectedModelNav, selectedOrgNav, selectedChatSettings } = useNavStore();

  const {
    selectedChatId,
    chatResponseData,
    isStreaming,
    isLoading,
    setIsStreaming,
    handleSelectChat,
    resetChatState,
    updateBotMessage,
    setChatResponseData,
    selectedChatTittle
  } = useChatState(selectedOrgNav);

  const { onChatSubmitHandler, stopStreamConnection } = useChatSubmission(
    user_id,
    selectedOrgNav,
    selectedModelNav,
    selectedChatId,
    handleSelectChat,
    updateBotMessage,
    setChatResponseData,
    resetChatState,
    selectedChatSettings,
    setIsStreaming
  );

  const [showChatDetails, setShowChatDetails] = useState(false);
  const [showChatList, setShowChatList] = useState(true);

  const toggleChatDetails = useCallback(() => {
    setShowChatDetails((prev) => !prev);
  }, []);

  const showChatProps = useMemo(() => ({
    messages: chatResponseData,
    waitingForSystem: isLoading || isStreaming ? "Loading" : "Ready",
    isStreaming,
    isLoading,
  }), [chatResponseData, isLoading, isStreaming]);

  const chatInputProps = useMemo(() => ({
    onChatSubmitHandler,
    isStreaming,
    setIsStreaming,
    onStopStream: stopStreamConnection
  }), [onChatSubmitHandler, isStreaming, stopStreamConnection]);

  const leftSideChatListProps = useMemo(() => ({
    user: user_id,
    selectedOrgNav,
    setShowChatList,
    onNewChat: resetChatState,
    onSelectChat: handleSelectChat
  }), [user_id, selectedOrgNav, resetChatState, handleSelectChat]);

  return (
    <div className="relative flex h-[99.9%] w-full overflow-hidden">
      <div
        className={`absolute left-0 top-0 h-full transform transition-transform duration-300 ease-in-out bg-white border-r
        ${showChatList ? "translate-x-0" : "-translate-x-full"}`}
      >
        <div className="h-full max-w-200">
          <LeftSideChatListCanvas {...leftSideChatListProps} />
        </div>
      </div>
      <div
        className={`
        flex-1 flex flex-col 
        transition-all duration-300 
        ${showChatList ? "md:w-[calc(100%-18rem)] md:ml-[18rem]" : "w-full"}
        ${showChatDetails ? "md:w-[calc(100%-25rem)] md:mr-[25rem]" : ""}
      `}
      >
        <div className="h-full flex flex-col overflow-hidden">
          {/* Chat Header */}
          <div className="flex items-center justify-between px-4 pt-3 border-b">
            <h2 className="text-xl font-semibold">
             { selectedChatTittle}
            </h2>
            <button
              onClick={toggleChatDetails}
              className="p-2 hover:bg-gray-100 rounded-full transition-colors"
            >
              <SlidersHorizontal className="h-5 w-5" />
            </button>
          </div>

          {/* Chat Content */}
          <div className="flex-1 flex flex-col overflow-hidden">
            <div className="overflow-y-auto flex-1">
              <ShowChat {...showChatProps} />
            </div>
            <div className="chat-input-container-main relative bottom-0 bg-white">
              <ChatInput {...chatInputProps} />
            </div>
          </div>
        </div>
      </div>

      <div
        className={`absolute right-0 top-0 transform transition-transform duration-300 ease-in-out bg-white border-l
        ${showChatDetails ? "translate-x-0" : "translate-x-full"}`}
      >
        <ChatDetailsDrawer
          className=""
          isOpen={showChatDetails}
          onClose={() => setShowChatDetails(false)}
        />
      </div>
    </div>
  );
};

export default memo(ChatCreate);