import React from 'react';
import { Box } from '@mui/material';

function ActivityHeatmap({ data }) {
  const getColor = (value) => {
    const colors = ['#ebedf0', '#9be9a8', '#40c463', '#30a14e', '#216e39'];
    return colors[value];
  };

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(52, 1fr)',
        gap: 1,
        width: '100%',
        height: 300,
        overflowX: 'auto',
      }}
    >
      {data.map((item, index) => (
        <Box
          key={index}
          sx={{
            width: '100%',
            paddingBottom: '100%',
            backgroundColor: getColor(item.value),
            borderRadius: 1,
          }}
          title={`${item.value} contributions`}
        />
      ))}
    </Box>
  );
}

export default ActivityHeatmap;

