import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import { Settings, User, Search, ChevronDown } from "lucide-react";
import "./Header.css";
import ProfileMenu from "./ProfileMenu";
import useAuthUserStore from "../Store/useAuthUserStore";
import { ApiSource } from "../api";
import useNavStore from "../Store/useNavStore";

const navItems = [
  { id: "playground", label: "Playground", path: "/playground" },
  //  { id: "dashboard", label: "Dashboard", path: "/dashboard" },
  { id: "docs", label: "Docs", path: "/docs" },
  //{ id: "api-reference", label: "API reference", path: "/api-reference" },
];

const SearchableSelect = React.memo(({ options, value, onChange, placeholder }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const selectRef = useRef(null);

  const safeOptions = Array.isArray(options) ? options : [];

  useEffect(() => {
    const filtered = safeOptions.filter((option) =>
      option.Name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredOptions(filtered);
  }, [searchTerm, safeOptions]);

  useEffect(() => {
    if (safeOptions.length > 0 && !value) {
      onChange(safeOptions[0]);
    }
  }, [safeOptions, value, onChange]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
        setSearchTerm("");
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("touchstart", handleClickOutside);
    }

    // Cleanup event listeners
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, [isOpen]);

  const handleSelect = (option) => {
    onChange(option);
    setIsOpen(false);
    setSearchTerm("");
  };

  return (
    <div className="searchable-select" ref={selectRef}>
      <div className="select-header" onClick={() => setIsOpen(!isOpen)}>
        <span>{value ? value.Name : placeholder}</span>
        <ChevronDown size={16} />
      </div>
      {isOpen && (
        <div className="select-dropdown">
          <div className="search-input">
            <Search size={16} />
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search..."
              onClick={(e) => e.stopPropagation()}
            />
          </div>
          <ul className="options-list">
            {filteredOptions.map((option) => (
              <li
                key={option.ID}
                onClick={() => handleSelect(option)}
                className={value && value.ID === option.ID ? "selected" : ""}
              >
                {option.Name}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
});

const Header = () => {
  const [organization, setOrganization] = useState(null);
  const [model, setModel] = useState(null);
  const [organizations, setOrganizations] = useState([]);
  const [models, setModels] = useState([]);
  const [error, setError] = useState(null);
  const [isChatPath, setIsChatPath] = useState(false);
  const { user,resetAuth } = useAuthUserStore();
  const location = useLocation();
  const { setSelectedModelNav, setSelectedOrgNav, messageStreaming } = useNavStore();
  const memoizedNavItems = useMemo(() => navItems, []);

  const[openProfileMenu,setOpenProfileMenu]=useState(false)

  const handleOrgChange = useCallback(
    (selectedOrg) => {
      setOrganization(selectedOrg);
      setSelectedOrgNav(selectedOrg);
    },
    [setSelectedOrgNav]
  );
  const handleLogout = async () => {
    await ApiSource.logout(user.id);
    resetAuth();
    setOpenProfileMenu(false);
    
    setTimeout(() => {
      Navigate('/auth');
    }, 100);
  }

  const handleModelChange = useCallback(
    (selectedModel) => {
      setModel(selectedModel);
      setSelectedModelNav(selectedModel);
    },
    [setSelectedModelNav]
  );

  useEffect(() => {
    if (location.pathname.includes("/chat")) {
      setIsChatPath(true);
    } else {
      setIsChatPath(false);
    }
  }, [location]);

  useEffect(() => {
    const fetchData = async () => {
      setError(null);
      try {
        const organizationsResponse = await ApiSource.fetchUserOrganizations(user?.id);

        const modelsResponse = await ApiSource.fetchModel(organizationsResponse.body.organizations[0]?.ID);

        const fetchedOrganizations = Array.isArray(
          organizationsResponse.body.organizations
        )
          ? organizationsResponse.body.organizations
          : [];
        const fetchedModels = Array.isArray(modelsResponse.body.models)
          ? modelsResponse.body.models
          : [];

        setOrganizations(fetchedOrganizations);
        setModels(fetchedModels);

        if (fetchedOrganizations.length > 0) {
          setOrganization(fetchedOrganizations[0]);
          setSelectedOrgNav(fetchedOrganizations[0]); // Set the selected org in the store
        }
        if (fetchedModels.length > 0) {
          setModel(fetchedModels[0]);
          setSelectedModelNav(fetchedModels[0]); // Set the selected model in the store
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
      }
    };

    user && fetchData();
  }, [user, setSelectedOrgNav, setSelectedModelNav]);



  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <nav className="navbar">
      <div className="navbar-left">
        <div className="navbar-brand">
          <span className="brand-icon">P</span>
          <SearchableSelect
            options={organizations}
            value={organization}
            onChange={handleOrgChange}
            placeholder="Select organization"
          />
          {isChatPath && (
            <>
              <span className="separator">/</span>
              <SearchableSelect
                options={models}
                value={model}
                onChange={handleModelChange}
                placeholder="Select model"
              />
            </>
          )}
        </div>
      </div>
      <div className="navbar-right">
        <div className="nav-links">
          {memoizedNavItems.map((item) => (
            <Link key={item.id} to={item.path} className="nav-link">
              {item.label}
            </Link>
          ))}
        </div>
        <Link to={`/settings/${user?.id}`} className="settings-btn">
          <Settings size={20} />
        </Link>
        <div onClick={()=>setOpenProfileMenu(!openProfileMenu)} className="profile-btn">
            {user?.profile_pic ? (
              <img
                src={user.profile_pic}
                alt="Profile"
                className="profile-image"
              />
            ) : (
              <User size={20} />
            )}
          </div>
          {openProfileMenu &&<ProfileMenu openProfileMenu={openProfileMenu} setOpenProfileMenu={setOpenProfileMenu}user={user} handleLogout={handleLogout}/>}
      </div>
    </nav>
  );
};

export default Header;
export { SearchableSelect };
