import React, { useState } from 'react';
import { 
  Select, 
  MenuItem, 
  FormControl,
  styled
} from '@mui/material';

const models = {
  'OpenAI': ['gpt-4', 'gpt-3.5-turbo'],
  'Anthropic': ['claude-3-opus', 'claude-3-sonnet'],
  'Google': ['gemini-pro', 'gemini-ultra'],
  'Groq': ['mixtral-8x7b', 'llama2-70b'],
  'Fireworks': ['llama-v2-7b', 'llama-v2-13b']
};

// Custom styled Select component with no border
const StyledSelect = styled(Select)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'none', // Remove the border
    },
    '&:hover fieldset': {
      borderColor: 'none', // No change on hover
    },
    '&.Mui-focused fieldset': {
      borderColor: 'none', // No change on focus
    },
  },
  '& .MuiSelect-select': {
    paddingRight: '32px !important',
  },
  '& .MuiSelect-icon': {
    right: '7px',
  },
  // Reduce scroller width
  '& .MuiMenu-paper': {
    width: '250px',
    maxWidth: '90%',
  },
  // Customize scrollbar
  '& .MuiMenu-list': {
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,0.2)',
      borderRadius: '3px',
    },
  }
});

export default function ModelSelector({ 
  selectedModel, 
  onModelChange
}) {
  const handleModelChange = (e) => {
    onModelChange(e.target.value);
  };

  return (
    <FormControl variant="outlined" size="small">
      <StyledSelect
        value={selectedModel}
        onChange={handleModelChange}
        renderValue={(selected) => {
          const provider = Object.keys(models).find(
            provider => models[provider].includes(selected)
          );
          return `${selected} (${provider})`;
        }}
      >
        {Object.entries(models).map(([provider, providerModels]) => [
          <MenuItem key={provider} disabled sx={{ fontWeight: 'bold', opacity: 0.7 }}>
            {provider}
          </MenuItem>,
          ...providerModels.map(model => (
            <MenuItem key={model} value={model}>
              {model}
            </MenuItem>
          ))
        ])}
      </StyledSelect>
    </FormControl>
  );
}
