import React, { useState } from "react";
import { Navigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { 
  Box, 
  IconButton, 
  styled,
  alpha,
  ThemeProvider,
  createTheme,
  CssBaseline,
  Tooltip
} from "@mui/material";
import DeveloperModeOutlinedIcon from '@mui/icons-material/DeveloperModeOutlined';
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import { BarChart3, Home, MessageCircle, User, PuzzleIcon as PuzzlePiece, BoxIcon, Moon, Sun, Settings, PuzzleIcon } from 'lucide-react';
import './LeftSidebar.css';
import { TheaterComedySharp } from "@mui/icons-material";
import useAuthUserStore from '../Store/useAuthUserStore';
const SidebarContainer = styled(Box)(({ theme }) => ({
  width: 60,
  backgroundColor: theme.palette.mode === 'dark' ? '#0A0A0A' : '#F3F4F6',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '16px 0',
  position: 'fixed',
  left: 0,
  top: 0,
  height: '100%',
}));

const NavButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'active',
})(({ theme, active }) => ({
  color: active ? (theme.palette.mode === 'dark' ? '#22C55E' : '#059669') : (theme.palette.mode === 'dark' ? '#6B7280' : '#4B5563'),
  width: '100%',
  height: 48,
  borderRadius: 0,
  position: 'relative',
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' ? '#141517' : '#D1FAE5',
    color: theme.palette.mode === 'dark' ? '#22C55E' : '#059669',
  },
  ...(active && {
    backgroundColor: theme.palette.mode === 'dark' ? '#1C4532' : '#D1FAE5',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.mode === 'dark' ? '#1C4532' : '#D1FAE5',
      boxShadow: `0 0 20px ${alpha(theme.palette.mode === 'dark' ? '#22C55E' : '#059669', 0.2)}`,
    },
    '& svg': {
      filter: `drop-shadow(0 0 8px ${alpha(theme.palette.mode === 'dark' ? '#22C55E' : '#059669', 0.4)})`,
    },
  }),
  '& svg': {
    width: 20,
    height: 20,
    position: 'relative',
    zIndex: 1,
  },
}));

const LeftSidebar = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const { user } = useAuthUserStore();
  if (user===null)
  {
    <Navigate to="/" replace />
  }
  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };
  const theme = createTheme({
    palette: {
      mode: isDarkMode ? 'dark' : 'light',
    },
  });
 
  const navButtons = [
    // {
    //   name: "dashboard",
    //   icon: <BarChart3 />,
    //   path: `/dashboard/${user?.id}`,
    //   tooltip: "Dashboard"
    // },
    {
      name: "chat",
      icon: <MessageCircle />,
      path: `/chat/${user?.id}`,
      tooltip: "Chat"
    },
    // {
    //   name: "integrations",
    //   icon: <PuzzleIcon />,
    //   path: `/integrations/${user?.id}`,
    //   tooltip: "Integrations"
    // },
    {
      name: "Organization Manager",
      icon: <TheaterComedySharp/>,
      path: `/organization_manager/${user?.id}`,
      tooltip: "Organization Manager"
    }
  ];

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SidebarContainer className="Sidebar-container">
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: '100%' }}>
          {navButtons.map(({ name, icon, path, tooltip }) => (
            <Tooltip key={name} title={tooltip} placement="right" arrow>
              <Link to={path} style={{ textDecoration: 'none', width: '100%' }}>
                <NavButton>
                  {icon}
                </NavButton>
              </Link>
            </Tooltip>
          ))}
        </Box>
        <Box sx={{ mt: 'auto', display: 'flex', flexDirection: 'column', gap: 1, width: '100%', marginBottom: 3}}>
          <Tooltip title={isDarkMode ? "Light Mode" : "Dark Mode"} placement="right" arrow>
            <NavButton onClick={toggleTheme}>
              {isDarkMode ? <Sun /> : <Moon />}
            </NavButton>
          </Tooltip>
          <Tooltip title="Help" placement="right" arrow>
            <Link to="/help" style={{ textDecoration: 'none', width: '100%' }}>
              <NavButton>
                <HelpCenterOutlinedIcon/>  
              </NavButton>
            </Link>
          </Tooltip>
          <Tooltip title="CookBook" placement="right" arrow>
            <Link to="/cookbook" style={{ textDecoration: 'none', width: '100%' }}>
              <NavButton>
                <DeveloperModeOutlinedIcon/>
              </NavButton>
            </Link>
          </Tooltip>
        </Box>
      </SidebarContainer>
    </ThemeProvider>
  );
};

export default LeftSidebar;