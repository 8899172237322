import React, { useEffect, useState } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import useAuthUserStore from '../Store/useAuthUserStore';
import SignIn from '../SignIn';

const AuthPage = () => {
  const location = useLocation();
  const { user, setUser, setAccessToken } = useAuthUserStore();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const callbackData = searchParams.get('data');

    if (callbackData) {
      const parsedData = JSON.parse(decodeURIComponent(callbackData));
      setAccessToken(parsedData.access_token);
      sessionStorage.setItem('accessToken', parsedData.access_token);
      setUser(parsedData.user);
      sessionStorage.setItem('user', JSON.stringify(parsedData.user));
    } else {
      const storedAccessToken = sessionStorage.getItem('accessToken');
      const storedUser = JSON.parse(sessionStorage.getItem('user'));

      if (storedAccessToken && storedUser) {
        setAccessToken(storedAccessToken);
        setUser(storedUser);
      }
    }
  }, [location, setUser, setAccessToken]);

  return (
    <div>
      {user ? (
        <Navigate to={`/chat/${user.id}`} replace />
      ) : (
        <SignIn />
      )}
    </div>
  );
};

export default AuthPage;