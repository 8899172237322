// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ol.TipOrderedList-editor-input-box {
  list-style: decimal;
  padding-left: 20px;
}
ul.TipBulletList-editor-input-box {
  list-style: disc;
  padding-left: 20px;
}
pre.code-block {
  background: #a9a9a94d;
  font-weight: 400;
  margin-bottom: 0px !important;
}
.chatinput-box-file-preview {
  display: inline-grid;
  padding: 3px;
}
button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.chat-input-box-send-icon.css-aixiz9-MuiButtonBase-root-MuiIconButton-root {
  /* margin-bottom: 0px; */
  margin-right: 12px;
  margin-bottom: 2px;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.chat-input-box-container.css-1m1h09u-MuiPaper-root {
  transition: all 0.2s cubic-bezier(0, 0, 0.76, -0.11);
  border-radius: 0.5rem;
  border-top-width:2px;
  border-top: 1px solid #b4b8bd;
}`, "",{"version":3,"sources":["webpack://./src/LandingPage/ChatInput.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;AACA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,6BAA6B;AAC/B;AACA;EACE,oBAAoB;EACpB,YAAY;AACd;AACA;EACE,wBAAwB;EACxB,kBAAkB;EAClB,kBAAkB;AACpB;AACA;EACE,oDAAoD;EACpD,qBAAqB;EACrB,oBAAoB;EACpB,6BAA6B;AAC/B","sourcesContent":["ol.TipOrderedList-editor-input-box {\n  list-style: decimal;\n  padding-left: 20px;\n}\nul.TipBulletList-editor-input-box {\n  list-style: disc;\n  padding-left: 20px;\n}\npre.code-block {\n  background: #a9a9a94d;\n  font-weight: 400;\n  margin-bottom: 0px !important;\n}\n.chatinput-box-file-preview {\n  display: inline-grid;\n  padding: 3px;\n}\nbutton.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.chat-input-box-send-icon.css-aixiz9-MuiButtonBase-root-MuiIconButton-root {\n  /* margin-bottom: 0px; */\n  margin-right: 12px;\n  margin-bottom: 2px;\n}\n.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.chat-input-box-container.css-1m1h09u-MuiPaper-root {\n  transition: all 0.2s cubic-bezier(0, 0, 0.76, -0.11);\n  border-radius: 0.5rem;\n  border-top-width:2px;\n  border-top: 1px solid #b4b8bd;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
