import React, { useEffect, useState } from 'react';
import { 
  Box, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem,
} from '@mui/material';
import { ApiSource } from '../../api';

const StyledSelect = ({ label, options = [], value, onChange }) => {
  return (
    <FormControl fullWidth size="small">
      <InputLabel>{label}</InputLabel>
      <Select
        value={value}
        label={label}
        onChange={onChange}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 300
            }
          }
        }}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const ChatSettings = ({settings, setSettings}) => {
  const [settingsOptions, setSettingsOptions] = useState({});
  const [providers, setProviders] = useState({});

  useEffect(() => {
    const fetchChatSettingsData = () => {
      return ApiSource.fetchChatSettings()
        .then((response) => {
          setSettingsOptions(response.body);
          return response;
        })
        .catch((error) => {
          console.error("Error fetching chats:", error.message);
          throw error;
        });
    };

    fetchChatSettingsData().catch((err) => console.error("Fetch failed:", err));
  }, []); 
 
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>      
        <StyledSelect
          label="Output Format"
          options={settingsOptions.outputFormats || []}
          value={settings.outputFormat}
          onChange={(e) => setSettings(prev => ({ ...prev, outputFormat: e.target.value }))}
        />
        
        <StyledSelect
          label="Tone"
          options={settingsOptions.tones || []}
          value={settings.tone}
          onChange={(e) => setSettings(prev => ({ ...prev, tone: e.target.value }))}
        />
        
        <StyledSelect
          label="Language"
          options={settingsOptions.languages || ['default']}
          value={settings.language}
          onChange={(e) => setSettings(prev => ({ ...prev, language: e.target.value }))}
        />
        
        <StyledSelect
          label="Writing Style"
          options={settingsOptions.writingStyles || []}
          value={settings.writingStyle}
          onChange={(e) => setSettings(prev => ({ ...prev, writingStyle: e.target.value }))}
        />
    </Box>
  );
};

export default ChatSettings;