import React, { useState, useEffect, useRef } from "react";
import "./ShowChat.css";
import icons from "../Icons";
import remarkGfm from "remark-gfm";
import SyntaxHighlighter from "react-syntax-highlighter";
import ReactMarkdown from "react-markdown";
import { solarizedDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import useAuthUserStore from "../Store/useAuthUserStore";

// Constants
const ICONS = {
  upload: <icons.Upload />,
  generator: <icons.Mic />, 
  system: <icons.BotMessageSquare />,
  user: <icons.User />
};

// Components
const CodeBlock = ({ content, language }) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(content);
    alert("Code copied!");
  };

  return (
    <div className="code-block-wrapper">
      <SyntaxHighlighter
        children={content}
        wrapLongLines={true}
        language={language || "plain"}
        PreTag="div"
        style={solarizedDark}
      />
      <button className="copy-button" onClick={handleCopy}>
        <icons.Copy size={14} />
      </button>
    </div>
  );
};

const MessageContent = ({ type, text, isMarkdown }) => {
  if (type === "message_raw") {
    return <div className="cell-output" dangerouslySetInnerHTML={{ __html: text }} />;
  }

  if (type.startsWith("image/")) {
    return (
      <div className="cell-output-image">
        <img src={`data:${type};base64,${text}`} alt="Generated content" />
      </div>
    );
  }

  if (isMarkdown) {
    return (
      <ReactMarkdown
        children={text}
        remarkPlugins={[remarkGfm]}
        components={{
          code({ node, inline, className, children, ...props }) {
            const match = /language-(\w+)/.exec(className || "");
            const codeContent = String(children).replace(/\n$/, "");
            
            if (!inline && match) {
              return <CodeBlock content={codeContent} language={match[1]} />;
            }
            
            return <code {...props} className={className}>{children}</code>;
          },
          a({ node, children, ...props }) {
            return (
              <a {...props} style={{ color: '#3b82f6' }} className="underline">
                {children}
              </a>
            );
          }
        }}
      />
    );
  }

  return <div className="cell-output">{text}</div>;
};

const Message = ({ text, role, type, modelName, isStreaming, createdAt }) => {
  const messageRef = useRef(null);
  const [displayText, setDisplayText] = useState(text);
  const { user } = useAuthUserStore();
  
  useEffect(() => {
    if (isStreaming) {
      setDisplayText(text);
    }
  }, [text, isStreaming]);

  const isMarkdown = (input) => {
    const mdRegex = /\[.*\]$$.*$$|\*\*.*\*\*|__.*__|\#.*|\!\[.*\]$$.*$$|`.*`|\- .*|\|.*\|/g;
    return mdRegex.test(input);
  };

  return (
    <>
      <div className={`message-avatar-badge ${role === "user" ? "user" : "system"}`}>
        <div className="flex items-center space-x-2">
          <div className="avatar-holder">
            <div className="avatar">{ICONS[role]}</div>
          </div>
          <span className={`px-2 py-0.5 text-xs font-semibold rounded-full ${role === "user" ? "user bg-gray-200 text-gray-800" : "system bg-gray-200 text-gray-800"}`}>
            {role === "user" ? user.name : modelName}
          </span>
          <span className="time-show-chat">{createdAt}</span>
        </div>
      </div>

      <div className={`message ${role} ${isStreaming ? "streaming" : ""}`} ref={messageRef}>
        <div className={`message-body ${role === "user" ? "justify-end" : "w-full"}`}>
          <div className={`message-content ${isStreaming ? "streaming" : ""}`}>
            <MessageContent 
              type={type || "message"} 
              text={displayText}
              isMarkdown={isMarkdown(displayText)}
            />
          </div>
        </div>

        <div className={`message-actions ${role === "user" ? "justify-end" : ""}`}>
          {role === "user" && (
            <button className="action-button">
              <icons.Edit size={12} />
            </button>
          )}
        </div>
      </div>
    </>
  );
};

const ShowChat = ({ messages }) => {
  const messagesEndRef = useRef(null);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <div className="chat-container">
      <div className="chat-messages">
        {Array.isArray(messages) && messages.map((message) => (
          <Message
            key={message.id}
            text={message.content}
            role={message.role === "assistant" ? "system" : "user"}
            type={message.type || "message"}
            isStreaming={message.isStreaming}
            modelName={message.model}
            createdAt={message.createdAt}
          />
        ))}
        <div ref={messagesEndRef} />
      </div>
    </div>
  );
};

export default ShowChat;
