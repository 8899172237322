import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  Snackbar,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { User, Lock, Users, Bell, Cloud } from "lucide-react";
import { ApiSource } from "../../api";
import TabContainer from "./components/TabContainer";
import GeneralSettings from "./components/GeneralSettings";
import AccountSettings from "./components/AccountSettings";
import NotificationSettings from "./components/NotificationSettings";
import AIProviderSettings from "./components/AIProviderSettings";
import CloudSyncSettings from "./components/CloudSyncSettings";

const Settings = () => {
  const [currentTab, setCurrentTab] = useState("general");
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState({
    organizations: [],
    providers: [],
  });

  const muiTheme = useTheme();
  const isMobile = useMediaQuery(muiTheme.breakpoints.down("sm"));
  const{id:user_id}=useParams();
  useEffect(() => {
    const loadData = async () => {
      try {
        const [organizationsResponse, providersResponse] = await Promise.all([
          ApiSource.fetchUserOrganizations(user_id),
          ApiSource.fetchProviders(),
        ]);
        setDropdownOptions({
          organizations: organizationsResponse.body,
          providers: providersResponse.body,
        });
      } catch (err) {
        console.error("Failed to fetch data:", err);
      }
    };
    loadData();
  }, [user_id]);

  const tabs = [
    { id: "general", label: "General", icon: <User /> },
    { id: "account", label: "Account", icon: <Lock /> },
    { id: "notifications", label: "Notifications", icon: <Bell /> },
    { id: "ai-providers", label: "AI Providers", icon: <Users /> },
    { id: "cloud-sync", label: "Cloud Sync", icon: <Cloud /> },
  ];

  const renderTabContent = () => {
    switch (currentTab) {
      case "general":
        return <GeneralSettings />;
      case "account":
        return <AccountSettings isMobile={isMobile} />;
      case "notifications":
        return <NotificationSettings />;
      case "ai-providers":
        return (
          <AIProviderSettings
            dropdownOptions={dropdownOptions}
            userId={user_id}
            setShowSnackbar={setShowSnackbar}
          />
        );
      case "cloud-sync":
        return <CloudSyncSettings />;
      default:
        return null;
    }
  };

  return (
    <Box sx={{ p: 3, width: "100%", margin: "0 auto" }}>
      <Typography
        variant="h4"
        sx={{ mb: 3, fontWeight: 600, fontSize: "24px", color: "#111827" }}
      >
        Settings
      </Typography>
      <Snackbar
        open={showSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={3000}
        onClose={() => setShowSnackbar(false)}
        message="API key added successfully"
      />
      <TabContainer
        tabs={tabs}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
      />
      {renderTabContent()}
    </Box>
  );
};

export default Settings;
