import React from 'react';
import { Github, Chrome, Mail, Apple, Twitter, Linkedin, Instagram } from 'lucide-react';
import { apiConfig } from '../src/api/config/apiConfig';
const SignIn = () => {
  const socialButtons = [
    { icon: <Chrome size={25} />, name: 'google', color: 'text-red-500' },
    { icon: <svg className="w-7 h-7" fill="currentColor" viewBox="0 0 24 24"><path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"/></svg>, name: 'facebook', color: 'text-blue-600' },
    { icon: <Mail size={25} />, name: 'microsoft', color: 'text-blue-500' },
    { icon: <Github size={25} />, name: 'github', color: 'text-gray-700' }
  ];

  const socialLinks = [
    { icon: <Twitter size={18} />, url: 'https://twitter.com/muxeai', label: 'Twitter' },
    { icon: <Linkedin size={18} />, url: 'https://linkedin.com/company/muxeai', label: 'LinkedIn' },
    { icon: <Github size={18} />, url: 'https://github.com/muxeai', label: 'GitHub' },
    { icon: <Instagram size={18} />, url: 'https://instagram.com/muxeai', label: 'Instagram' }
  ];

  const handleLogin = (provider) => {
    window.location.href = `${apiConfig.mauthUrl}/${provider}`;
  };

  return (
    <div className="flex h-screen relative">
      <div className="w-1/2 flex flex-col bg-gray-100">
        <div className="flex-grow flex items-center justify-center p-8">
          <div className="max-w-sm w-full space-y-8">
            <div className="text-center">
              <h1 className="text-2xl font-bold">muxe.ai</h1>
              <h2 className="mt-6 text-xl">Login to your account</h2>
            </div>

            <div className="grid grid-cols-3 gap-4 px-8">
              {socialButtons.map((button) => (
                <button
                  key={button.name}
                  onClick={() => handleLogin(button.name)}
                  title={`Continue with ${button.name}`}
                  className={`bg-white ${button.color} w-14 h-14 rounded-xl flex items-center justify-center
                  shadow-[5px_5px_10px_#b8b9be,-5px_-5px_10px_#ffffff]
                  hover:shadow-[inset_5px_5px_10px_#b8b9be,inset_-5px_-5px_10px_#ffffff]
                  transition-all duration-200 transform hover:scale-95`}
                >
                  {button.icon}
                </button>
              ))}
            </div>

            <div className="text-center space-y-2 text-gray-600">
              <p>Don't have an account? <a href="#" className="text-blue-500">Create Account</a></p>
              <p><a href="#" className="text-blue-500">Need Help?</a></p>
            </div>
          </div>
        </div>

        <footer className="p-6 text-center text-gray-600">
          <div className="flex justify-center space-x-4 mb-4">
            {socialLinks.map((link) => (
              <a
                key={link.label}
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-500 hover:text-gray-700 transition-colors"
                title={link.label}
              >
                {link.icon}
              </a>
            ))}
          </div>
          <p className="text-sm">
            © {new Date().getFullYear()} muxe.ai. All rights reserved.
          </p>
        </footer>
      </div>

      <div className="w-1/2 bg-black text-white p-12 flex flex-col justify-center">
        <div className="max-w-lg">
          <h2 className="text-2xl font-bold mb-4">Product Webinars</h2>
          <div className="mb-8">
            <button className="border border-yellow-500 text-yellow-500 px-4 py-2 rounded-lg">
              Register Today
            </button>
          </div>
          
          <div className="space-y-6">
            <h3 className="text-xl font-bold">Remote Browser Isolation</h3>
            <p className="text-gray-300">
              Secure cloud-based and internal web applications by controlling browsing sessions with full auditing, session recording and credential autofill.
            </p>
            <button className="border border-white px-4 py-2 rounded-lg">
              Learn More
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;