import React from 'react';
import { ThemeProvider, createTheme, CssBaseline, Box } from '@mui/material';
import Dashboard from './DashboardComponent/Dashboard';

const theme = createTheme({
  palette: {
    primary: {
      main: '#8884d8',
    },
  },
});

function DashboardApp() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ minHeight: '100vh', bgcolor: 'background.default' }}>
        <Dashboard />
      </Box>
    </ThemeProvider>
  );
}

export default DashboardApp;

