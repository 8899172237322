export const encodeQueryParams = (params) => {
  return Object.keys(params).map((key) => {
    if (Array.isArray(params[key])) {
      return params[key]
        .map((el) => [key, el].map(encodeURIComponent).join('='))
        .join('&');
    } else {
      return [key, params[key]].map(encodeURIComponent).join('=');
    }
  }).join('&');
};
