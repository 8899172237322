import React, { useState } from "react";
import { Box, Typography, FormControl, InputLabel, MenuItem, TextField, Button } from "@mui/material";
import { Users, ChevronDown } from 'lucide-react';
import StyledCard from "./StyledCard";
import StyledSelect from "./StyledSelect";
import SectionTitle from "./SectionTitle";
import { ApiSource } from "../../../api";

const AIProviderSettings = ({ dropdownOptions, userId, setShowSnackbar }) => {
  const [state, setState] = useState({
    selectedOrg: "",
    selectedProvider: "",
    apiKey: ""
  });

  const handleStateChange = (field) => (event) => {
    setState(prevState => ({
      ...prevState,
      [field]: event.target.value
    }));
  };

  const handleAddApiKey = async () => {
    const { apiKey, selectedOrg, selectedProvider } = state;

    if (!apiKey) {
      alert("Please provide an API key");
      return;
    }

    const reqBody = {
      user_id: userId,
      org_id: selectedOrg,
      provider_id: selectedProvider,
      api_key: apiKey,
    };

    try {
      const response = await ApiSource.addApiKey(reqBody);
      console.log("API Key added successfully:", response.body);
      setShowSnackbar(true);
      
      // Reset the state after successful API key addition
      setState({
        selectedOrg: "",
        selectedProvider: "",
        apiKey: ""
      });
      
    } catch (err) {
      console.error("Failed to add API key:", err);
    }
  };

  return (
    <StyledCard>
      <Box sx={{ p: 3 }}>
        <SectionTitle icon={<Users />} title="AI Providers" />
        <Typography variant="body2" sx={{ mb: 4, color: "#6B7280", gap: 16 }}>
          Configure your third-party AI provider settings and select models
        </Typography>
        <FormControl fullWidth sx={{ mb: 3 }}>
          <InputLabel>Select Organization</InputLabel>
          <StyledSelect
            value={state.selectedOrg}
            label="Select Organization"
            onChange={handleStateChange('selectedOrg')}
            IconComponent={ChevronDown}
          >
            {dropdownOptions.organizations.organizations.map((org) => (
              <MenuItem key={org.ID} value={org.ID}>
                {org.Name}
              </MenuItem>
            ))}
          </StyledSelect>
        </FormControl>

        {state.selectedOrg && (
          <FormControl fullWidth sx={{ mb: 3 }}>
            <InputLabel>Select provider</InputLabel>
            <StyledSelect
              value={state.selectedProvider}
              label="Select provider"
              onChange={handleStateChange('selectedProvider')}
              IconComponent={ChevronDown}
            >
              {dropdownOptions.providers.providers.map((provider) => (
                <MenuItem key={provider.ID} value={provider.ID}>
                  {provider.Name}
                </MenuItem>
              ))}
            </StyledSelect>
          </FormControl>
        )}

        {state.selectedProvider && state.selectedOrg && (
          <TextField
            label="API Key"
            type="password"
            value={state.apiKey}
            onChange={handleStateChange('apiKey')}
            placeholder="Enter your API key"
            fullWidth
            variant="outlined"
            sx={{ mb: 3 }}
          />
        )}

        {state.selectedProvider && state.selectedOrg && (
          <Button
            onClick={handleAddApiKey}
            disabled={!state.apiKey}
            fullWidth
            variant="contained"
            color="primary"
          >
            Add Provider
          </Button>
        )}
      </Box>
    </StyledCard>
  );
};

export default AIProviderSettings;
