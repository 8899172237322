import { styled } from "@mui/material/styles";
import { Select } from "@mui/material";

const StyledSelect = styled(Select)({
  "& .MuiSelect-select": {
    padding: "10px 14px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#E5E7EB",
  },
});

export default StyledSelect;