// src/LandingPage/RoutesComponent.jsx
import React from "react";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import DashboardApp from "./DashboardApp";
import SettingsApp from "./SettingsPage/SettingsApp";
import WorkspaceManager from "./WorkSpaceManagerPages/WorkSpaceManager";
import OrganizationManager from "./organizationPages/OrganizationManager";
import ChatCreate from "./ChatCreate";
import AuthPage from "./AuthPage";
import LeftSidebar from "./LeftSidebar";
import Header from "./Header";
import useAuthUserStore from "../Store/useAuthUserStore";

const RoutesComponent = ({ showSidebar, showChatList }) => {
  const location = useLocation();
  const { user } = useAuthUserStore();
  const isAuthPage = location.pathname === "/";

  return (
    <div className="flex h-screen bg-white dark:bg-gray-900">
      {!isAuthPage && (
        <div className="flex-shrink-0">
          <LeftSidebar user={user} />
        </div>
      )}
      <div
        className={`flex flex-col flex-1 ${showSidebar && !isAuthPage ? "ml-16" : ""
          }`}
      >
        {!isAuthPage && <Header />}

        <main className="flex-1 ml-16 mt-12 h-[96%] bg-white text-gray-800 dark:bg-gray-900 dark:text-gray-500">
          <Routes>
            <Route path="/" element={<AuthPage />} />
            <Route
              path="/dashboard/:id"
              element={user ? <DashboardApp /> : <Navigate to="/" replace />}
            />
            <Route
              path="/settings/:id"
              element={user ? <SettingsApp /> : <Navigate to="/" replace />}
            />
            <Route
              path="/organization_manager/:id"
              element={user ? <OrganizationManager /> : <Navigate to="/" replace />}
            />
            <Route
              path="/chat/:id"
              element={user ? <ChatCreate /> : <Navigate to="/" replace />}
            />
            <Route path="/" element={<Navigate to="/" replace />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </main>
      </div>
    </div>
  );
};

export default RoutesComponent;