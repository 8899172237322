import ApiProxy from "./utils/ApiProxy";
import { routes } from "./routes";
const ApiSource = {
  getChatById: async (chat_id) => {
    return await ApiProxy.GET({
      path: `/chats/${chat_id}/messages`,
    });
  },

  createChat: async (bodyPayload) => {
    return await ApiProxy.POST({
      path: routes.chat,
      bodyPayload: bodyPayload,
    });
  },
  createChatById: async (bodyPayload, chat_id) => {
    return await ApiProxy.POST({
      path: `/chats/${chat_id}/messages`,
      bodyPayload,
    });
  },

  fetchAllChat: async (param) => {
    return await ApiProxy.GET({
      path: routes.chat,
      params: { 
        user_id: param.user_id, 
        organization_id: param.organization_id 
      },
    });
  },
  
  fetchModel: async (organization_id) => {
    return await ApiProxy.GET({
      path: routes.models,
      params: { 
        organization_id: organization_id 
      },
    });
  },
  fetchChatSettings: async () => {
    return await ApiProxy.GET({
      path: routes.ChatSettings,
    });
  },

  fetchProviders: async () => {
    return await ApiProxy.GET({
      path: routes.providers,
    });
  },

  addTeamMember: async (team_member_data) => {
    return await ApiProxy.POST({
      path: `/team-members`,
      bodyPayload: team_member_data,
    });
  },

  createOrganization: async (user_id, org_data) => {
    return await ApiProxy.POST({
      path: `/organizations`,
      params: {
        user_id,
      },
      bodyPayload: org_data,
    });
  },
  fetchUserPendingInvites: async (userId) => {
    return await ApiProxy.GET({
      path: `/users/${userId}/pending-invites`,
    });
  },
  fetchUserOrganizations: async (userId) => {
    return await ApiProxy.GET({
      path: `/users/${userId}/organizations`,
    });
  },
  getTeamMembersByOrganization: async (organization_id) => {
    return await ApiProxy.GET({
      path: `/organizations/${organization_id}/team-members`,
    });
  },

  logout: async (user_id) => {
    return await ApiProxy.mauthCall({
      path: '/logout',
      bodyPayload: {user_id},
    });
  },

  addApiKey: async (api_key_data) => {
    return await ApiProxy.POST({
      path: `/apikey`,
      bodyPayload: api_key_data,
    });
  },
};

export default ApiSource;