import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  TextField,
  Button,
  Tabs,
  Tab,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Chip,
  Snackbar,
  Alert,
} from "@mui/material";
import {
  Add as AddIcon,
  Person as PersonIcon,
  Mail as MailIcon,
} from "@mui/icons-material";
import ProvidersTab from "./ProvidersTab";
import { ApiSource } from "../../api";
import FullPageLoader from "../FullPageLoader";
import { useParams } from "react-router-dom";

const OrganizationManager = () => {
  const { id: user_id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [teams, setTeams] = useState([]);
  const [newTeamName, setNewTeamName] = useState("");
  const [newMemberEmail, setNewMemberEmail] = useState({});
  const [newInviteEmail, setNewInviteEmail] = useState({});
  const [toast, setToast] = useState({
    open: false,
    message: "",
    severity: "error"
  });

  const handleCloseToast = () => {
    setToast({ ...toast, open: false });
  };

  const handleTabChange = (teamId, newValue) => {
    setTeams(teams.map((team) => 
      team.ID === teamId ? { ...team, activeTab: newValue } : team
    ));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const { body: { organizations } } = await ApiSource.fetchUserOrganizations(user_id);
        
        const teamsWithMembers = await Promise.all(
          organizations.map(async (org) => {
            const { body: { members } } = await ApiSource.getTeamMembersByOrganization(org.ID);
            return {
              ...org,
              members,
              activeTab: 0,
            };
          })
        );

        setTeams(teamsWithMembers);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error);
        setToast({
          open: true,
          message: "Failed to fetch organizations and members",
          severity: "error"
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [user_id]);

  if (isLoading) {
    return <FullPageLoader message="Loading organizations and teams..." />;
  }

  if (error) {
    return <div>Error loading data: {error.message}</div>;
  }

  const addTeam = async () => {
    const trimmedTeamName = newTeamName.trim();
    if (!trimmedTeamName) return;

    try {
      setIsLoading(true);
      const { body: newOrg } = await ApiSource.createOrganization(user_id, {
        name: trimmedTeamName,
        description: "New team description",
      });

      if (newOrg && newOrg.organization) {
        const { body: { members } } = await ApiSource.getTeamMembersByOrganization(newOrg.organization.ID);
        
        setTeams(prevTeams => [...prevTeams, {
          ...newOrg.organization,
          members,
          pendingInvites: [],
          activeTab: 0,
        }]);
        setNewTeamName("");
        setToast({
          open: true,
          message: "Team created successfully",
          severity: "success"
        });
      }
    } catch (error) {
      console.error("Failed to create organization:", error);
      setToast({
        open: true,
        message: "Failed to create new team",
        severity: "error"
      });
    } finally {
      setIsLoading(false);
    }
  };

  const addMemberToTeam = async (teamId) => {
    const email = newMemberEmail[teamId]?.trim();
    if (!email) return;

    try {
      const { body: newMember } = await ApiSource.addTeamMember({
        email,
        org_id: teamId,
        role: "member",
        invited_by: user_id,
      });

      if (newMember) {
        setTeams(prevTeams => prevTeams.map(team => 
          team.ID === teamId ? {
            ...team,
            members: [...team.members, {
              id: newMember.ID,
              email,
              role: newMember.role || "member",
              status: newMember.status || "pending",
            }],
          } : team
        ));
        setNewMemberEmail(prev => ({ ...prev, [teamId]: "" }));
        setToast({
          open: true,
          message: "Team member added successfully",
          severity: "success"
        });
      }
    } catch (error) {
      console.error("Failed to add team member:", error);
      setToast({
        open: true,
        message: "Failed to add team member",
        severity: "error"
      });
    }
  };

  const sendInvite = (teamId) => {
    const email = newInviteEmail[teamId]?.trim();
    if (!email) return;

    try {
      setTeams(prevTeams => prevTeams.map(team =>
        team.id === teamId ? {
          ...team,
          pendingInvites: [...team.pendingInvites, email],
        } : team
      ));
      setNewInviteEmail(prev => ({ ...prev, [teamId]: "" }));
      setToast({
        open: true,
        message: "Invite sent successfully",
        severity: "success"
      });
    } catch (error) {
      setToast({
        open: true,
        message: "Failed to send invite",
        severity: "error"
      });
    }
  };

  const isAddMemberDisabled = (teamId) => !newMemberEmail[teamId]?.trim();
  const isSendInviteDisabled = (teamId) => !newInviteEmail[teamId]?.trim();

  const renderTabContent = (team) => {
    const activeTab = team.activeTab ?? 0;

    switch (activeTab) {
      case 0:
        return (
          <Box>
            <List>
              {team.members.map((member, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={member.email}
                    secondary={member.role}
                  />
                  <ListItemSecondaryAction>
                    <Chip
                      label={member.role}
                      color={member.role === "owner" ? "primary" : "default"}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
            <Box display="flex" alignItems="center" mt={2}>
              <TextField
                label="New member email"
                variant="outlined"
                size="small"
                value={newMemberEmail[team.ID] || ""}
                onChange={(e) => setNewMemberEmail(prev => ({
                  ...prev,
                  [team.ID]: e.target.value,
                }))}
              />
              <Button
                variant="contained"
                startIcon={<PersonIcon />}
                onClick={() => addMemberToTeam(team.ID)}
                disabled={isAddMemberDisabled(team.ID)}
                sx={{ ml: 1 }}
              >
                Add
              </Button>
            </Box>
          </Box>
        );

      case 1:
        return (
          <Box>
            <List>
              {team.pendingInvites?.map((email, index) => (
                <ListItem key={index}>
                  <ListItemText primary={email} secondary="Pending" />
                </ListItem>
              ))}
            </List>
            <Box display="flex" alignItems="center" mt={2}>
              <TextField
                label="Email to invite"
                variant="outlined"
                size="small"
                value={newInviteEmail[team.id] || ""}
                onChange={(e) => setNewInviteEmail(prev => ({
                  ...prev,
                  [team.id]: e.target.value,
                }))}
              />
              <Button
                variant="contained"
                startIcon={<MailIcon />}
                onClick={() => sendInvite(team.id)}
                disabled={isSendInviteDisabled(team.id)}
                sx={{ ml: 1 }}
              >
                Send Invite
              </Button>
            </Box>
          </Box>
        );

      case 2:
        return <ProvidersTab />;

      default:
        return null;
    }
  };

  return (
    <Card sx={{ height: "100vh", overflow: "hidden" }}>
      <CardHeader title="Teams Manager" />
      <CardContent sx={{ height: "calc(100vh - 72px)", overflowY: "auto" }}>
        <Box>
          <Box display="flex" alignItems="center" my={2}>
            <TextField
              label="New team name"
              variant="outlined"
              size="small"
              value={newTeamName}
              onChange={(e) => setNewTeamName(e.target.value)}
            />
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={addTeam}
              sx={{ ml: 1 }}
            >
              Add Team
            </Button>
          </Box>
          {teams.map((team) => (
            <Card key={team.ID} variant="outlined" sx={{ mb: 2 }}>
              <CardHeader
                title={team.Name}
                subheader={`${team.members.length} members`}
              />
              <CardContent>
                <Tabs
                  value={team.activeTab}
                  onChange={(_, newValue) => handleTabChange(team.ID, newValue)}
                >
                  {["Members", "Invites", "Providers"].map((label, index) => (
                    <Tab
                      sx={{ size: 8, height: 16 }}
                      key={index}
                      label={label}
                    />
                  ))}
                </Tabs>
                <Box mt={2}>{renderTabContent(team)}</Box>
              </CardContent>
            </Card>
          ))}
        </Box>
      </CardContent>
      <Snackbar 
        open={toast.open} 
        autoHideDuration={6000} 
        onClose={handleCloseToast}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseToast} severity={toast.severity}>
          {toast.message}
        </Alert>
      </Snackbar>
    </Card>
  );
};

export default OrganizationManager;
