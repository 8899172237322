// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.model-config-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 2px;
}

.model-config-section {
  margin-bottom: 16px;
}

.model-config-header {
  font-weight: 600;
  margin-bottom: 8px;
}

.model-config-slider-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.model-config-functions-paper {
  padding: 16px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.model-config-add-function-btn {
  margin-top: 8px;
}

.model-config-divider {
  margin: 16px 0;
}`, "",{"version":3,"sources":["webpack://./src/LandingPage/ChatSettingsComponent/ModelConfig.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,YAAY;AACd;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,qCAAqC;EACrC,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".model-config-container {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  padding: 2px;\n}\n\n.model-config-section {\n  margin-bottom: 16px;\n}\n\n.model-config-header {\n  font-weight: 600;\n  margin-bottom: 8px;\n}\n\n.model-config-slider-label {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 8px;\n}\n\n.model-config-functions-paper {\n  padding: 16px;\n  border: 1px solid rgba(0, 0, 0, 0.12);\n  border-radius: 4px;\n}\n\n.model-config-add-function-btn {\n  margin-top: 8px;\n}\n\n.model-config-divider {\n  margin: 16px 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
