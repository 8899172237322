// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiDrawer-root.MuiDrawer-modal.chat-details-drawer.MuiModal-root.css-2soe65-MuiModal-root-MuiDrawer-root {
  position: static !important;
}
.chat-Configuration-header{
  font-family: "Inter", sans-serif;
  padding: 14px 0px 0px 0px;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}

.chat-details-drawer {
  width: 400px;
}

.chat-details-drawer-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.chat-details-drawer-content {
  height: calc(100% - 160px);
  overflow-y: auto;
}

.chat-details-drawer-tabs {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.chat-details-drawer-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  background-color: white;
}`, "",{"version":3,"sources":["webpack://./src/LandingPage/ChatSettingsComponent/ChatDetailsDrawer.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;AAC7B;AACA;EACE,gCAAgC;EAChC,yBAAyB;EACzB,yBAAyB;EACzB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,iBAAiB;EACjB,4CAA4C;AAC9C;;AAEA;EACE,0BAA0B;EAC1B,gBAAgB;AAClB;;AAEA;EACE,4CAA4C;AAC9C;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,QAAQ;EACR,aAAa;EACb,yCAAyC;EACzC,uBAAuB;AACzB","sourcesContent":[".MuiDrawer-root.MuiDrawer-modal.chat-details-drawer.MuiModal-root.css-2soe65-MuiModal-root-MuiDrawer-root {\n  position: static !important;\n}\n.chat-Configuration-header{\n  font-family: \"Inter\", sans-serif;\n  padding: 14px 0px 0px 0px;\n  font-optical-sizing: auto;\n  font-weight: 300;\n  font-style: normal;\n}\n\n.chat-details-drawer {\n  width: 400px;\n}\n\n.chat-details-drawer-header {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 4px 16px;\n  border-bottom: 1px solid rgba(0, 0, 0, 0.12);\n}\n\n.chat-details-drawer-content {\n  height: calc(100% - 160px);\n  overflow-y: auto;\n}\n\n.chat-details-drawer-tabs {\n  border-bottom: 1px solid rgba(0, 0, 0, 0.12);\n}\n\n.chat-details-drawer-footer {\n  position: absolute;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  padding: 16px;\n  border-top: 1px solid rgba(0, 0, 0, 0.12);\n  background-color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
