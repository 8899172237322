import * as React from 'react';
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Tabs,
  Tab,
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export function WorkspaceManager() {
  const [workspaceName, setWorkspaceName] = React.useState("My Workspace");
  const [teamMembers, setTeamMembers] = React.useState(["alice@example.com", "bob@example.com"]);
  const [newMember, setNewMember] = React.useState("");
  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const addTeamMember = () => {
    if (newMember && !teamMembers.includes(newMember)) {
      setTeamMembers([...teamMembers, newMember]);
      setNewMember("");
    }
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          Workspace Manager
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
          Manage your AI workspace and team
        </Typography>

        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabValue} onChange={handleTabChange} aria-label="workspace tabs">
            <Tab label="General" />
            <Tab label="Team" />
          </Tabs>
        </Box>

        <TabPanel value={tabValue} index={0}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              label="Workspace Name"
              value={workspaceName}
              onChange={(e) => setWorkspaceName(e.target.value)}
              fullWidth
            />
            <Box>
              <Button variant="contained" color="primary">
                Save Changes
              </Button>
            </Box>
          </Box>
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <TextField
                label="Add Team Member"
                value={newMember}
                onChange={(e) => setNewMember(e.target.value)}
                placeholder="Email address"
                fullWidth
              />
              <Button variant="contained" onClick={addTeamMember}>
                Add
              </Button>
            </Box>

            <Box>
              <Typography variant="subtitle2" gutterBottom>
                Team Members
              </Typography>
              <List>
                {teamMembers.map((member, index) => (
                  <ListItem
                    key={index}
                    sx={{
                      bgcolor: 'action.hover',
                      borderRadius: 1,
                      mb: 1
                    }}
                    secondaryAction={
                      <IconButton
                        edge="end"
                        onClick={() => setTeamMembers(teamMembers.filter((_, i) => i !== index))}
                      >
                        <DeleteIcon />
                      </IconButton>
                    }
                  >
                    <ListItemText primary={member} />
                  </ListItem>
                ))}
              </List>
            </Box>
          </Box>
        </TabPanel>
      </CardContent>
    </Card>
  );
}

export default WorkspaceManager;