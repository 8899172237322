import React, { useState } from 'react';
import { Box, Tab, Tabs, Typography, Paper, Grid } from '@mui/material';
import { Users, MessageSquare, Mail, Folder } from 'lucide-react';
import MetricCard from './MetricCard';
import MonthlyActivity from './MonthlyActivity';
import ActivityHeatmap from './ActivityHeatmap';

function Dashboard() {
  const [currentTab, setCurrentTab] = useState(0);

  const metrics = [
    {
      title: 'Total Users',
      value: '1,234',
      change: '+20.1%',
      period: 'from last month',
      icon: <Users size={24} />,
    },
    {
      title: 'Active Chats',
      value: '567',
      change: '+15.5%',
      period: 'from last month',
      icon: <MessageSquare size={24} />,
    },
    {
      title: 'Messages Sent',
      value: '23,456',
      change: '+32.7%',
      period: 'from last month',
      icon: <Mail size={24} />,
    },
    {
      title: 'Active Projects',
      value: '89',
      change: '+7.2%',
      period: 'from last month',
      icon: <Folder size={24} />,
    },
  ];

  const monthlyData = [
    { month: 'Jan', value: 380 },
    { month: 'Feb', value: 290 },
    { month: 'Mar', value: 190 },
    { month: 'Apr', value: 270 },
    { month: 'May', value: 185 },
    { month: 'Jun', value: 235 },
  ];

  const generateHeatmapData = () => {
    const data = [];
    for (let i = 0; i < 52; i++) {
      for (let j = 0; j < 7; j++) {
        data.push({
          value: Math.floor(Math.random() * 4),
          day: j,
          week: i,
        });
      }
    }
    return data;
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Dashboard
      </Typography>

      <Tabs
        value={currentTab}
        onChange={(e, newValue) => setCurrentTab(newValue)}
        sx={{ mb: 3 }}
      >
        <Tab label="Dashboard" />
        <Tab label="Analytics" />
        <Tab label="Alerts" />
      </Tabs>

      <Grid container spacing={3}>
        {metrics.map((metric, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <MetricCard {...metric} />
          </Grid>
        ))}

        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 3, height: '100%' }}>
            <Typography variant="h6" gutterBottom>
              Monthly Activity
            </Typography>
            <MonthlyActivity data={monthlyData} />
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 3, height: '100%' }}>
            <Typography variant="h6" gutterBottom>
              Activity Graph
            </Typography>
            <ActivityHeatmap data={generateHeatmapData()} />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Dashboard;

