import React, { useState, useRef, useCallback } from "react";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { CodeBlock } from "@tiptap/extension-code-block";
import { Code } from "@tiptap/extension-code";
import { BulletList } from "@tiptap/extension-bullet-list";
import { OrderedList } from "@tiptap/extension-ordered-list";
import { ListItem } from "@tiptap/extension-list-item";
import "./ChatInput.css";
import { dracula } from "react-syntax-highlighter/dist/esm/styles/hljs";
import {
  List as ListIcon,
  ListOrdered,
  Code as CodeIcon,
  Mic,
  Paperclip,
  Send,
  SquareDashedBottomCode,
  Video,
  X,
  CircleStop,
} from "lucide-react";
import {
  IconButton,
  Button,
  Input,
  Box,
  Paper,
  Snackbar,
  Alert,
} from "@mui/material";
import RecordingModal from "./RecordingModal";

const MAX_ATTACHMENTS = 5;

const MenuButton = ({ onClick, isActive, icon: Icon, tooltip }) => (
  <IconButton
    onClick={onClick}
    size="small"
    sx={{
      p: 1,
      bgcolor: isActive ? "action.hover" : "transparent",
      "&:hover": {
        bgcolor: isActive ? "action.hover" : "action.hover",
      },
    }}
    title={tooltip}
  >
    <Icon size={16} />
  </IconButton>
);

const TextFormatToolbar = ({ editor }) => {
  if (!editor) return null;

  const toolbarButtons = [
    { action: "toggleBulletList", icon: ListIcon, tooltip: "Bullet List" },
    {
      action: "toggleOrderedList",
      icon: ListOrdered,
      tooltip: "Numbered List",
    },
    { action: "toggleCode", icon: CodeIcon, tooltip: "Code" },
  ];

  return (
    <Box sx={{ display: "flex", gap: 0.5 }}>
      {toolbarButtons.map(({ action, icon, tooltip }, index) => (
        <MenuButton
          key={index}
          onClick={() => editor.chain().focus()[action]().run()}
          isActive={editor.isActive(action.replace("toggle", "").toLowerCase())}
          icon={icon}
          tooltip={tooltip}
        />
      ))}
    </Box>
  );
};

const AttachmentPreview = ({ attachment, onRemove }) => {
  const mediaTypes = [
    {
      type: "image",
      icon: (
        <img
          src={attachment.preview}
          alt={attachment.name}
          style={{
            width: "140px",
            height: "85px",
            borderRadius: "8px",
            objectFit: "cover",
          }}
        />
      ),
    },
    {
      type: "video",
      icon: (
        <video
          src={attachment.preview}
          controls
          style={{
            width: "140px",
            height: "85px",
            borderRadius: "8px",
          }}
        />
      ),
    },
    {
      type: "audio",
      icon: (
        <audio
          controls
          style={{ width: "140px", height: "85px", borderRadius: "8px" }}
        >
          <source src={attachment.preview} />
        </audio>
      ),
    },
  ];

  const renderMedia = mediaTypes.find(
    (media) => media.type === attachment.type
  );

  return (
    <Box
      sx={{
        position: "relative",
        width: "140px",
        height: "85px",
        borderRadius: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        bgcolor: "grey.200",
        color: "grey.800",
      }}
    >
      <IconButton
        onClick={() => onRemove(attachment.id)}
        size="small"
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          p: 0.5,
        }}
      >
        <X size={16} />
      </IconButton>

      {renderMedia ? (
        renderMedia.icon
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Paperclip size={24} style={{ marginBottom: "8px" }} />
          <Box
            sx={{
              fontSize: "0.75rem",
              textOverflow: "ellipsis",
              overflow: "hidden",
              textAlign: "center",
            }}
          >
            {attachment.name}
          </Box>
        </Box>
      )}
    </Box>
  );
};

const ChatInput = ({ onChatSubmitHandler, isStreaming, setIsStreaming,onStopStream }) => {
  const [attachments, setAttachments] = useState([]);
  const [isMicRecording, setIsMicRecording] = useState(false);
  const [recordingStream, setRecordingStream] = useState(null);
  const [isCodeMode, setIsCodeMode] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const fileInputRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const recordedChunksRef = useRef([]);

  const customTheme = {
    ...dracula,
    metastring: "color: #f8f8f2;", // customize metastring color
    editor: {
      ...dracula.editor,
      padding: "1.25rem", // set padding for the editor
    },
  };
  const editor = useEditor({
    extensions: [
      StarterKit,
      BulletList.configure({
        HTMLAttributes: {
          class: "list-disc ml-4",
        },
      }),
      OrderedList.configure({
        HTMLAttributes: {
          class: "list-decimal ml-4",
        },
      }),
      ListItem,
      CodeBlock.configure({
        code: customTheme,
        ...customTheme,
        languageClassPrefix: "language-",
        HTMLAttributes: {
          class: "rounded-md bg-gray-800 p-4",
        },
      }),
      Code,
    ],
    content: "",
    editorProps: {
      attributes: {
        class:
          "chat-box-editor min-h-[40px] max-h-[175px] outline-none overflow-y-auto p-2 prose prose-sm max-w-none",
      },
    },
  });

  const handleFileUpload = useCallback(
    (e) => {
      const newFiles = Array.from(e.target.files);
      const totalFiles = attachments.length + newFiles.length;

      if (totalFiles > MAX_ATTACHMENTS) {
        setAlertMessage(
          `You can only attach up to ${MAX_ATTACHMENTS} files. Please remove some files first.`
        );
        setShowAlert(true);
        e.target.value = null;
        return;
      }

      const files = newFiles.map((file) => ({
        id: Math.random().toString(36).substr(2, 9),
        type: file.type.split("/")[0],
        file,
        preview: URL.createObjectURL(file),
        name: file.name,
      }));

      setAttachments((prev) => [...prev, ...files]);
      e.target.value = null;
    },
    [attachments]
  );

  const handleRemoveAttachment = useCallback((id) => {
    setAttachments((prev) => prev.filter((a) => a.id !== id));
  }, []);

  const handleMicRecording = useCallback(async () => {
    if (!isMicRecording) {
      if (attachments.length >= MAX_ATTACHMENTS) {
        setAlertMessage(
          `You can only attach up to ${MAX_ATTACHMENTS} files. Please remove some files first.`
        );
        setShowAlert(true);
        return;
      }

      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        setRecordingStream(stream);

        const mediaRecorder = new MediaRecorder(stream);
        mediaRecorderRef.current = mediaRecorder;
        recordedChunksRef.current = [];

        mediaRecorder.ondataavailable = (e) => {
          if (e.data.size > 0) recordedChunksRef.current.push(e.data);
        };

        mediaRecorder.onstop = () => {
          const audioBlob = new Blob(recordedChunksRef.current, {
            type: "audio/webm",
          });
          const audioFile = new File([audioBlob], "recording.webm", {
            type: "audio/webm",
          });
          setAttachments((prev) => [
            ...prev,
            {
              id: Math.random().toString(36).substr(2, 9),
              type: "audio",
              file: audioFile,
              preview: URL.createObjectURL(audioBlob),
              name: "Voice Recording.webm",
            },
          ]);
        };
        mediaRecorder.start();
        setIsMicRecording(true);
      } catch (err) {
        setAlertMessage(
          "Unable to access microphone. Please check permissions."
        );
        setShowAlert(true);
      }
    } else {
      mediaRecorderRef.current?.stop();
      recordingStream?.getTracks().forEach((track) => track.stop());
      setRecordingStream(null);
      setIsMicRecording(false);
    }
  }, [isMicRecording, attachments.length]);

  const handleUploadScreenRecording = (preview) => {
    if (attachments.length >= MAX_ATTACHMENTS) {
      setAlertMessage(
        `You can only attach up to ${MAX_ATTACHMENTS} files. Please remove some files first.`
      );
      setShowAlert(true);
      setIsOpen(false);
      return;
    }

    setIsOpen(false);
    if (preview?.blob) {
      setAttachments((prev) => [
        ...prev,
        {
          id: Math.random().toString(36).substr(2, 9),
          type: "video",
          file: preview.blob,
          preview: URL.createObjectURL(preview.blob),
          name: preview.blob.name,
        },
      ]);
    }
  };

  const toggleCodeMode = useCallback(() => {
    if (editor) {
      editor.chain().focus().toggleCodeBlock().run();
      setIsCodeMode((prev) => !prev);
    }
  }, []);

  const handleSubmit = () => {
    setIsStreaming(true);

    if ((editor && !editor.isEmpty) || attachments.length > 0) {
      const content = editor.getJSON();
      onChatSubmitHandler({ content, attachments });
      editor.commands.clearContent();
      setAttachments([]);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSubmit();
    }
  };

  const chatIcons = [
    {
      id: "code",
      icon: SquareDashedBottomCode,
      onClick: toggleCodeMode,
      tooltip: "Toggle Code Mode",
    },
    {
      id: "attachment",
      icon: Paperclip,
      onClick: () => {
        if (attachments.length >= MAX_ATTACHMENTS) {
          setAlertMessage(
            `You can only attach up to ${MAX_ATTACHMENTS} files. Please remove some files first.`
          );
          setShowAlert(true);
          return;
        }
        fileInputRef.current?.click();
      },
      tooltip: "Attach File",
    },
    {
      id: "mic",
      icon: Mic,
      onClick: handleMicRecording,
      tooltip: "Voice Recording",
      sx: { bgcolor: isMicRecording ? "error.light" : "transparent" },
    },
    {
      id: "video",
      icon: Video,
      onClick: () => {
        if (attachments.length >= MAX_ATTACHMENTS) {
          setAlertMessage(
            `You can only attach up to ${MAX_ATTACHMENTS} files. Please remove some files first.`
          );
          setShowAlert(true);
          return;
        }
        setIsOpen(!isOpen);
      },
      tooltip: "Video Recording",
    },
  ];

  return (
    <>
      <Paper
        className="chat-input-box-container"
        elevation={1}
        sx={{ bgcolor: "background.paper" }}
      >
        <EditorContent editor={editor} onKeyDown={handleKeyDown} />
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 2 }}>
          {attachments.length > 0 &&
            attachments.map((attachment) => (
              <AttachmentPreview
                key={attachment.id}
                attachment={attachment}
                onRemove={handleRemoveAttachment}
              />
            ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mt: 2,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <TextFormatToolbar editor={editor} />
            {chatIcons.map(({ id, icon: Icon, onClick, tooltip, sx = {} }) => (
              <IconButton
                key={id}
                size="small"
                onClick={onClick}
                title={tooltip}
                sx={sx}
              >
                <Icon size={16} />
              </IconButton>
            ))}
            <Input
              type="file"
              multiple
              inputRef={fileInputRef}
              onChange={handleFileUpload}
              sx={{ display: "none" }}
            />
          </Box>
          <IconButton
            className="chat-input-box-send-icon"
            onClick={isStreaming ? onStopStream : handleSubmit}
            size="medium"
            disabled={!isStreaming && editor?.isEmpty && attachments.length === 0}
          >
            {isStreaming ? (
              <CircleStop size={16} />
            ) : (
              <Send size={16} />
            )}
          </IconButton>
        </Box>
        <Snackbar
          open={showAlert}
          autoHideDuration={6000}
          onClose={() => setShowAlert(false)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={() => setShowAlert(false)}
            severity="warning"
            sx={{ width: "100%" }}
          >
            {alertMessage}
          </Alert>
        </Snackbar>
      </Paper>
      {isOpen && (
        <RecordingModal
          handleUploadScreenRecording={handleUploadScreenRecording}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
        />
      )}
    </>
  );
};

export default ChatInput;
