import React from "react";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

const TabButton = styled("button")(({ active }) => ({
  padding: "8px 12px",
  background: active ? "#fff" : "transparent",
  border: "none",
  borderRadius: "6px",
  cursor: "pointer",
  fontSize: "14px",
  color: active ? "#111827" : "#6B7280",
  fontWeight: 500,
  "&:hover": {
    color: "#111827",
  },
}));

const StyledTabContainer = styled(Box)({
  display: "flex",
  gap: "8px",
  padding: "4px",
  background: "#F3F4F6",
  borderRadius: "8px",
  marginBottom: "24px",
});

const TabContainer = ({ tabs, currentTab, setCurrentTab }) => {
  return (
    <StyledTabContainer>
      {tabs.map((tab) => (
        <TabButton
          key={tab.id}
          active={currentTab === tab.id}
          onClick={() => setCurrentTab(tab.id)}
        >
          {tab.label}
        </TabButton>
      ))}
    </StyledTabContainer>
  );
};

export default TabContainer;

