import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Drawer,
  Typography,
  Tab,
  Tabs,
  IconButton,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import ChatSettings from "./ChatSettings";
import ModelConfig from "./ModelConfig";
import './ChatDetailsDrawer.css';
import useNavStore from "../../Store/useNavStore";
import { ApiSource } from "../../api";

function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`config-tabpanel-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default function ChatDetailsDrawer({ isOpen, onClose }) {
  const [tabValue, setTabValue] = useState(0);
  const { selectedChatSettings, setSelectedChatSettings } = useNavStore();

  const [modelConfig, setModelConfig] = useState({
    temperature: 1,
    maxTokens: 2048,
    topP: 1,
    frequencyPenalty: 0,
    presencePenalty: 0,
    model: 'gpt-4-0524',
    responseFormat: 'text'
  });

  const [settings, setSettings] = useState({
    outputFormat: selectedChatSettings?.outputFormat || "Default",
    tone: selectedChatSettings?.tone || "Default",
    language: selectedChatSettings?.language || "Default",
    writingStyle: selectedChatSettings?.writingStyle || "Default",
  });

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSaveData = () => {
    if (tabValue === 1) {
      setSelectedChatSettings(settings);
    }
    // TODO: Implement model config saving logic
  };

  useEffect(() => {
    const fetchAllModel = async () => {
      try {
        await ApiSource.fetchModel();
      } catch (error) {
        console.error("Error fetching or parsing chats:", error);
      }
    };

    fetchAllModel();
  }, []);

  return (
    <Drawer
      className="chat-details-drawer"
      anchor="right"
      open={isOpen}
      onClose={onClose}
      ModalProps={{
        BackdropProps: {
          invisible: true
        },
        keepMounted: true,
        disableAutoFocus: true,
        disableEnforceFocus: true,
      }
    }
      PaperProps={{
        sx: {
          marginTop: "48px",
          width: 400,
          height: "calc(100% - 40px)",
          boxShadow: 3,
        },
      }}
      BackdropProps={{
        onClick: (event) => event.stopPropagation(),
      }}
    >
      <div className="chat-details-drawer-header">
        <Typography variant="h6">
          Chat Configuration
        </Typography>
        <IconButton onClick={onClose} edge="end">
          <CloseIcon />
        </IconButton>
      </div>

      <div className="chat-details-drawer-content">
        <div className="chat-details-drawer-tabs">
          <Tabs 
            value={tabValue} 
            onChange={handleTabChange} 
            variant="fullWidth"
          >
            <Tab label="Model Config" />
            <Tab label="Chat Settings" />
          </Tabs>
        </div>

        <TabPanel value={tabValue} index={0}>
          <ModelConfig 
            config={modelConfig} 
            onConfigChange={setModelConfig} 
          />
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <ChatSettings 
            settings={settings} 
            setSettings={setSettings} 
          />
        </TabPanel>
      </div>

      <div className="chat-details-drawer-footer">
        <Button 
          variant="contained" 
          fullWidth 
          onClick={handleSaveData}
        >
          Save as Preset
        </Button>
      </div>
    </Drawer>
  );
}