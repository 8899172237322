import React, { useState } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Tabs,
  Tab,
  Box,
  Switch,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from '@mui/material';
import {
  Message as MessageIcon,
  Psychology as BrainIcon,
  Memory as CpuIcon,
  SmartToy as BotIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';

const ProvidersTab = () => {
  const [activeProvider, setActiveProvider] = useState(0);
  const [providers, setProviders] = useState([
    {
      id: "openai",
      name: "OpenAI",
      icon: <MessageIcon />,
      enabled: true,
      models: [
        { id: "gpt-4", name: "GPT-4", enabled: true },
        { id: "gpt-4-turbo", name: "GPT-4 Turbo", enabled: true },
        { id: "gpt-3.5-turbo", name: "GPT-3.5 Turbo", enabled: true }
      ]
    },
    {
      id: "anthropic",
      name: "Anthropic",
      icon: <BrainIcon />,
      enabled: true,
      models: [
        { id: "claude-3-opus", name: "Claude 3 Opus", enabled: true },
        { id: "claude-3-sonnet", name: "Claude 3 Sonnet", enabled: true },
        { id: "claude-3-haiku", name: "Claude 3 Haiku", enabled: true }
      ]
    },
    {
      id: "groq",
      name: "Groq",
      icon: <CpuIcon />,
      enabled: false,
      models: [
        { id: "mixtral-8x7b", name: "Mixtral 8x7B", enabled: false },
        { id: "llama2-70b", name: "LLaMA2 70B", enabled: false }
      ]
    },
    {
      id: "llama",
      name: "Meta LLaMA",
      icon: <BotIcon />,
      enabled: false,
      models: [
        { id: "llama2-70b", name: "LLaMA2 70B", enabled: false },
        { id: "llama2-13b", name: "LLaMA2 13B", enabled: false },
        { id: "llama2-7b", name: "LLaMA2 7B", enabled: false }
      ]
    }
  ]);

  const toggleProvider = (providerId) => {
    setProviders(providers.map(provider => {
      if (provider.id === providerId) {
        const newEnabled = !provider.enabled;
        return {
          ...provider,
          enabled: newEnabled,
          models: provider.models.map(model => ({
            ...model,
            enabled: newEnabled ? model.enabled : false
          }))
        };
      }
      return provider;
    }));
  };

  const toggleModel = (providerId, modelId) => {
    setProviders(providers.map(provider => {
      if (provider.id === providerId) {
        return {
          ...provider,
          models: provider.models.map(model => 
            model.id === modelId 
              ? { ...model, enabled: !model.enabled }
              : model
          )
        };
      }
      return provider;
    }));
  };

  const handleProviderChange = (event, newValue) => {
    setActiveProvider(newValue);
  };

  return (
    <Card>
        <Tabs
          value={activeProvider}
          onChange={handleProviderChange}
          variant="fullWidth"
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            mb: 2
          }}
        >
          {providers.map((provider, index) => (
            <Tab
              key={provider.id}
              icon={provider.icon}
              label={provider.name}
              iconPosition="start"
              sx={{
                minHeight: 48,
                textTransform: 'none',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                gap: 1,
                '& .MuiTab-iconWrapper': {
                  marginRight: 1,
                  marginBottom: '0 !important'
                }
              }}
            />
          ))}
        </Tabs>

        {providers.map((provider, index) => (
          <Box
            key={provider.id}
            role="tabpanel"
            hidden={activeProvider !== index}
            sx={{ p: 2, border: 1, borderColor: 'divider', borderRadius: 1 }}
          >
            {activeProvider === index && (
              <>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  mb={2}
                >
                  <Box display="flex" alignItems="center" gap={1}>
                    {provider.icon}
                    <Typography variant="h6">{provider.name} Provider</Typography>
                  </Box>
                  <Switch
                    checked={provider.enabled}
                    onChange={() => toggleProvider(provider.id)}
                  />
                </Box>

                <List>
                  {provider.models.map(model => (
                    <ListItem
                      key={model.id}
                      sx={{
                        bgcolor: 'action.hover',
                        borderRadius: 1,
                        mb: 1
                      }}
                    >
                      <ListItemText
                        primary={model.name}
                        sx={{ my: 0 }}
                      />
                      <ListItemSecondaryAction>
                        <Switch
                          edge="end"
                          checked={provider.enabled && model.enabled}
                          disabled={!provider.enabled}
                          onChange={() => toggleModel(provider.id, model.id)}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </>
            )}
          </Box>
        ))}
    </Card>
  );
};

export default ProvidersTab;