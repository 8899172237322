import {create} from 'zustand';
const useNavStore = create((set) => ({
  selectedModelNav: null,
  selectedOrgNav: null,
  selectedChatSettings:null,
  messageStreaming:false,

  setSelectedModelNav: (selectedModelNav) =>
    set({ selectedModelNav }),
  setSelectedOrgNav: (selectedOrgNav) =>
    set({ selectedOrgNav }),
  setSelectedChatSettings: (selectedChatSettings) =>
    set({ selectedChatSettings }),
  setMessageStreaming: (messageStreaming) =>
    set({ messageStreaming }),
}));

export default useNavStore;