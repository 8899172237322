import React, { useState, useRef, useEffect } from 'react';
import { 
  Drawer, 
  List, 
  ListItem, 
  IconButton, 
  InputBase, 
  Box, 
  Typography,
  Collapse,
  Button
} from '@mui/material';
import { X, Share2, PlusCircle, ChevronDown, ChevronRight, MessageSquare } from 'lucide-react';
import { ApiSource } from '../api';

const LeftSideChatListCanvas = ({ user, selectedOrgNav, showChatList, setShowChatList, onNewChat, onSelectChat }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedSections, setExpandedSections] = useState({ Today: true, Yesterday: true });
  const [showAllChats, setShowAllChats] = useState(false);
  const [chatList, setChatList] = useState([]);
  const drawerRef = useRef(null);

  useEffect(() => {
    const fetchAllChat = async () => {
      try {
        const response = await ApiSource.fetchAllChat({ user_id: user, organization_id: selectedOrgNav.ID });
        setChatList(response.body.chats);
      } catch (error) {
        console.error("Error fetching or parsing chats:", error);
      }
    };
  
    fetchAllChat();
  }, [selectedOrgNav]);

  const mockChats = chatList?.map((chat) => ({
    id: chat.id,
    title: chat.title,
    updated_at: chat.updated_at,
  }));

  const handleDrawerClose = () => {
    setShowChatList(false);
  };

  const handleNewChat = () => {
    onNewChat();
    handleDrawerClose();
  };

  const handleClickOutside = (event) => {
    if (drawerRef.current && !drawerRef.current.contains(event.target)) {
      handleDrawerClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const filteredChats = mockChats?.filter(chat => 
    chat.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const groupChatsByDate = (chats) => {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    const lastWeek = new Date(today);
    lastWeek.setDate(lastWeek.getDate() - 7);

    return chats.reduce((groups, chat) => {
      const chatDate = new Date(chat.updated_at);
      let group;

      if (chatDate >= today) {
        group = 'Today';
      } else if (chatDate >= yesterday) {
        group = 'Yesterday';
      } else if (chatDate >= lastWeek) {
        group = 'Previous 7 Days';
      } else {
        group = 'Older';
      }

      if (!groups[group]) {
        groups[group] = [];
      }
      groups[group].push(chat);
      return groups;
    }, {});
  };

  const groupedChats = groupChatsByDate(filteredChats);

  const toggleSection = (section) => {
    setExpandedSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  const renderChatGroup = (group, chats) => (
    <React.Fragment key={group}>
      <Box
        onClick={() => toggleSection(group)}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
          mt: 2,
          mb: 1,
        }}
      >
        <Typography 
          variant="subtitle1" 
          sx={{ 
            fontWeight: 600,
            color: '#1a1a1a'
          }}
        >
          {group}
        </Typography>
        <IconButton size="small">
          {expandedSections[group] !== false ? <ChevronDown size={18} /> : <ChevronRight size={18} />}
        </IconButton>
      </Box>
      <Collapse in={expandedSections[group] !== false}>
        {chats.map((chat) => (
          <ListItem 
            key={chat.id} 
            sx={{ 
              padding: '8px 12px',
              borderRadius: 1,
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
              },
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
            onClick={() => onSelectChat(chat.id, chat.title)}
          >
            <Typography 
              sx={{ 
                flex: 1,
                color: '#1a1a1a',
                fontWeight: 500,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                mr: 1
              }}
            >
              {chat.title}
            </Typography>
            <IconButton 
              size="small" 
              sx={{ 
                opacity: 1
              }}
            >
              <Share2 size={18} />
            </IconButton>
          </ListItem>
        ))}
      </Collapse>
    </React.Fragment>
  );

  // Render empty state when no chats are available
  const renderEmptyState = () => (
    <Box 
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        textAlign: 'center',
        padding: 3,
        color: '#6b7280'
      }}
    >
      <MessageSquare 
        size={48} 
        strokeWidth={1.5} 
        className="mb-4 text-gray-400"
      />
      <Typography 
        variant="h6" 
        sx={{ 
          mb: 2,
          fontWeight: 600,
          color: '#1a1a1a'
        }}
      >
        No Chats Yet
      </Typography>
      <Typography 
        variant="body2" 
        sx={{ 
          mb: 3,
          color: '#6b7280'
        }}
      >
        Start a new conversation to see your chats here
      </Typography>
      <Button 
        variant="contained" 
        onClick={handleNewChat}
        sx={{
          textTransform: 'none',
          bgcolor: '#2563eb',
          '&:hover': {
            bgcolor: '#1d4ed8'
          }
        }}
      >
        New Chat
      </Button>
    </Box>
  );

  return (
    <Drawer
      anchor="left"
      open={true}
      onClose={handleDrawerClose}
      role="presentation"
      variant="persistent"
      sx={{
        width: 288,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: 289,
          boxSizing: "border-box",
        },
      }}
      ref={drawerRef}
    >
      <Box
        sx={{
          width: 288,
          padding: 2,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          bgcolor: '#f7f7f8'
        }}
      >
        {/* Conditional rendering for search and icons */}
        {mockChats?.length > 0 && (
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <InputBase
              placeholder="Search chats..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{ 
                flexGrow: 1, 
                mr: 1,
                bgcolor: 'white',
                borderRadius: 1,
                padding: '4px 8px'
              }}
            />
            <IconButton onClick={handleNewChat}>
              <PlusCircle />
            </IconButton>
            <IconButton onClick={handleDrawerClose}>
              <X />
            </IconButton>
          </Box>
        )}
        
        {/* Conditional rendering for empty state */}
        {mockChats?.length === 0 ? (
          renderEmptyState()
        ) : (
          <List sx={{ flexGrow: 1, overflowY: 'auto' }}>
            {renderChatGroup('Today', groupedChats['Today'] || [])}
            {renderChatGroup('Yesterday', groupedChats['Yesterday'] || [])}
            {!showAllChats && (groupedChats['Previous 7 Days'] || groupedChats['Older']) && (
              <Box sx={{ textAlign: 'center', mt: 2 }}>
                <Button onClick={() => setShowAllChats(true)}>View all</Button>
              </Box>
            )}
            {showAllChats && (
              <>
                {renderChatGroup('Previous 7 Days', groupedChats['Previous 7 Days'] || [])}
                {renderChatGroup('Older', groupedChats['Older'] || [])}
              </>
            )}
          </List>
        )}
      </Box>
    </Drawer>
  );
};

export default LeftSideChatListCanvas;