import React from "react";
import { Box, Typography } from "@mui/material";

const SectionTitle = ({ icon, title }) => (
  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
    {React.cloneElement(icon, { size: 24, color: "#667085" })}
    <Typography
      variant="h6"
      sx={{ ml: 2, color: "#101828", fontWeight: 600, fontSize: "18px" }}
    >
      {title}
    </Typography>
  </Box>
);

export default SectionTitle;

