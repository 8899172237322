import React from 'react';
import { Paper, Box, Typography } from '@mui/material';

function MetricCard({ title, value, change, period, icon }) {
  const isPositive = change.startsWith('+');

  return (
    <Paper sx={{ p: 3, height: '100%' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Box sx={{ mr: 2, color: 'primary.main' }}>{icon}</Box>
        <Typography variant="h6" component="h2">
          {title}
        </Typography>
      </Box>
      <Typography variant="h4" component="p" gutterBottom>
        {value}
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography
          variant="body2"
          sx={{
            color: isPositive ? 'success.main' : 'error.main',
            fontWeight: 'bold',
            mr: 1,
          }}
        >
          {change}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {period}
        </Typography>
      </Box>
    </Paper>
  );
}

export default MetricCard;

