// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Sidebar-container{
  margin-top:35px;
  z-index: 50;
}`, "",{"version":3,"sources":["webpack://./src/LandingPage/LeftSidebar.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,WAAW;AACb","sourcesContent":[".Sidebar-container{\n  margin-top:35px;\n  z-index: 50;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
