import React from 'react';
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Slider,
  TextField,
  Paper,
  Button,
  Divider
} from '@mui/material';
import { Add as PlusIcon } from '@mui/icons-material';
import './ModelConfig.css';

const DEFAULT_MODEL_CONFIG = {
  temperature: 1,
  maxTokens: 2048,
  topP: 1,
  frequencyPenalty: 0,
  presencePenalty: 0,
  model: 'gpt-4-0524',
  responseFormat: 'text'
};

export default function ModelConfig({ 
  config = DEFAULT_MODEL_CONFIG, 
  onConfigChange 
}) {
  const handleSliderChange = (field) => (_, value) => {
    onConfigChange({ ...config, [field]: value });
  };

  const handleSelectChange = (field) => (event) => {
    onConfigChange({ ...config, [field]: event.target.value });
  };

  return (
    <div className="model-config-container">
      <FormControl fullWidth>
        <InputLabel>Model</InputLabel>
        <Select
          value={config.model}
          label="Model"
          onChange={handleSelectChange('model')}
        >
          <MenuItem value="gpt-4-0524">gpt-4-0524</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth>
        <InputLabel>Response Format</InputLabel>
        <Select
          value={config.responseFormat}
          label="Response Format"
          onChange={handleSelectChange('responseFormat')}
        >
          <MenuItem value="text">text</MenuItem>
        </Select>
      </FormControl>

      {/* <Paper 
        elevation={0} 
        className="model-config-functions-paper"
      >
        <Typography 
          variant="subtitle1" 
          className="model-config-header"
        >
          Functions
        </Typography>
        <Button 
          variant="outlined" 
          startIcon={<PlusIcon />} 
          fullWidth
          className="model-config-add-function-btn"
        >
          Add
        </Button>
      </Paper> */}

      <Divider className="model-config-divider" />

      <Typography 
        variant="h6" 
        className="model-config-header"
      >
        Model Configuration
      </Typography>

      {[
        { 
          label: 'Temperature', 
          field: 'temperature', 
          min: 0, 
          max: 1, 
          step: 0.01 
        },
        { 
          label: 'Max Tokens', 
          field: 'maxTokens', 
          min: 0, 
          max: 4096, 
          step: 1 
        },
        { 
          label: 'Top P', 
          field: 'topP', 
          min: 0, 
          max: 1, 
          step: 0.01 
        },
        { 
          label: 'Frequency Penalty', 
          field: 'frequencyPenalty', 
          min: 0, 
          max: 2, 
          step: 0.01 
        },
        { 
          label: 'Presence Penalty', 
          field: 'presencePenalty', 
          min: 0, 
          max: 2, 
          step: 0.01 
        }
      ].map(({ label, field, min, max, step }) => (
        <div key={field} className="model-config-section">
          <div className="model-config-slider-label">
            <Typography>{label}</Typography>
            <Typography>{config[field]}</Typography>
          </div>
          <Slider
            value={config[field]}
            onChange={handleSliderChange(field)}
            min={min}
            max={max}
            step={step}
          />
        </div>
      ))}

      <TextField
        label="Stop Sequences"
        fullWidth
        helperText="Enter sequence and press Tab"
      />
    </div>
  );
}