import React, { useState } from "react";
import { Box, Typography, FormControl, InputLabel, MenuItem } from "@mui/material";
import { Cloud, ChevronDown } from 'lucide-react';
import StyledCard from "./StyledCard";
import StyledSelect from "./StyledSelect";
import SectionTitle from "./SectionTitle";

const CloudSyncSettings = () => {
  const [cloudSync, setCloudSync] = useState("");

  return (
    <StyledCard>
      <Box sx={{ p: 3 }}>
        <SectionTitle icon={<Cloud />} title="Cloud Sync" />
        <Typography variant="body2" sx={{ mb: 4, color: "#6B7280" }}>
          Configure your cloud backup and synchronization settings
        </Typography>

        <FormControl fullWidth>
          <InputLabel>Backup options</InputLabel>
          <StyledSelect
            value={cloudSync}
            label="Backup options"
            onChange={(e) => setCloudSync(e.target.value)}
            IconComponent={ChevronDown}
          >
            <MenuItem value="google">Google Drive</MenuItem>
            <MenuItem value="dropbox">Dropbox</MenuItem>
            <MenuItem value="icloud">iCloud</MenuItem>
            <MenuItem value="local">Local Backup</MenuItem>
          </StyledSelect>
        </FormControl>
      </Box>
    </StyledCard>
  );
};

export default CloudSyncSettings;

