import request from "superagent";
import { encodeQueryParams } from "./index";
import { globalConfig } from "../config";
import { apiConfig } from "../config/apiConfig";

const getAccessToken = () => {
  return sessionStorage.getItem('accessToken');
};

const setAccessToken = (token) => {
  sessionStorage.setItem('accessToken', token);
};

const clearToken = () => {
  sessionStorage.removeItem('accessToken');
};

const getHeaders = () => ({
  "Content-Type": "application/json",
  ...(getAccessToken() && { Authorization: `Bearer ${getAccessToken()}` }),
});

const apiCall = async ({
  service,
  verb,
  path,
  params = null,
  bodyPayload = null,
  method = "",
  apiType = "DefaultApiProxyRequest",
}) => {
  if (globalConfig.debugApiCall) {
    console.log("ApiProxyRequest:", service, verb, path, params, bodyPayload);
  }

  if (params) {
    path = `${path}?${encodeQueryParams(params)}`;
  }

  try {
    const res = await request(verb.toLowerCase(), globalConfig.baseUrl + path)
      .set(getHeaders())
      .send(bodyPayload);
    return res;
  } catch (err) {
    if (err.status === 401) {
      clearToken();
      throw new Error('Authentication failed. Please log in again.');
    }
    throw err;
  }
};

const mauthCall = async ({
  verb='post',
  path,
  params = null,
  bodyPayload = null,
}) => {
  if (params) {
    path = `${path}?${encodeQueryParams(params)}`;
  }

  try {
    const res = await request(verb.toLowerCase(), apiConfig.mauthUrl + path)
      .set(getHeaders())
      .send(bodyPayload);
    return res;
  } catch (err) {
    if (err.status === 401) {
      clearToken();
      throw new Error('Authentication failed. Please log in again.');
    }
    throw err;
  }
};

const GET = (params) => apiCall({ ...params, verb: "GET" });
const POST = (params) => apiCall({ ...params, verb: "POST" });
const PUT = (params) => apiCall({ ...params, verb: "PUT" });
const DELETE = (params) => apiCall({ ...params, verb: "DELETE" });
const PATCH = (params) => apiCall({ ...params, verb: "PATCH" });

const apiProxy = {
  apiCall,
  mauthCall, // Added mauthCall to the apiProxy
  GET,
  POST,
  PUT,
  DELETE,
  PATCH,
  setAccessToken,
  getAccessToken,
  clearToken,
};

export default apiProxy;
