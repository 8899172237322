import React from 'react';
import { ThemeProvider, createTheme, CssBaseline } from '@mui/material';
import Settings from './Settings';

const theme = createTheme({
  typography: {
    fontFamily: 'Inter, sans-serif',
  },
  palette: {
    background: {
      default: '#F9FAFB',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '8px',
          fontWeight: 600,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
          },
        },
      },
    },
  },
});

function SettingsApp() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Settings />
    </ThemeProvider>
  );
}

export default SettingsApp;

