import React from 'react';
import { Loader2 } from 'lucide-react';

const FullPageLoader = ({ 
  message = "Loading...", 
  size = 64, 
  className = "" 
}) => {
  return (
    <div className="fixed inset-0 z-[9999] flex items-center justify-center">
      <div className="flex flex-col items-center justify-center">
        <Loader2 
          className={`animate-spin text-blue-500 ${className}`} 
          size={size} 
        />
        {message && (
          <p className="mt-4 text-gray-800 text-lg font-semibold">{message}</p>
        )}
      </div>
    </div>
  );
};

export default FullPageLoader;