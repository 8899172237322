import { styled } from "@mui/material/styles";
import { Card } from "@mui/material";

const StyledCard = styled(Card)({
  boxShadow: "none",
  border: "1px solid #EAECF0",
  borderRadius: "12px",
});

export default StyledCard;

