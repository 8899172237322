// src/Store/useAuthUserStore.js
import { devtools } from 'zustand/middleware';
import {create} from 'zustand'
const useAuthUserStore = create(
  devtools((set) => ({
    user: null,
    accessToken: null,
    setUser: (user) => set({ user }),
    setAccessToken: (accessToken) => set({ accessToken }),
    resetAuth: () => {
        sessionStorage.removeItem('accessToken');
        sessionStorage.removeItem('user');
        set({ user: null, accessToken: null });
    },
  }))
);

export default useAuthUserStore;