import React, { useState, useEffect } from "react";
import { Box, Typography, Switch, FormControlLabel } from "@mui/material";
import { Bell } from 'lucide-react';
import StyledCard from "./StyledCard";
import SectionTitle from "./SectionTitle";

const NotificationSettings = () => {
  const [notificationsEnabled, setNotificationsEnabled] = useState(false);

  useEffect(() => {
    const requestNotificationPermission = async () => {
      if (notificationsEnabled && "Notification" in window) {
        try {
          const permission = await Notification.requestPermission();
          if (permission !== "granted") {
            setNotificationsEnabled(false);
          }
        } catch (error) {
          console.error("Error requesting notification permission:", error);
          setNotificationsEnabled(false);
        }
      }
    };

    requestNotificationPermission();
  }, [notificationsEnabled]);

  const handleNotificationChange = (event) => {
    setNotificationsEnabled(event.target.checked);
  };

  return (
    <StyledCard>
      <Box sx={{ p: 3 }}>
        <SectionTitle icon={<Bell />} title="Notification Settings" />
        <Typography variant="body2" sx={{ mb: 4, color: "#6B7280" }}>
          Manage your notification preferences
        </Typography>

        <FormControlLabel
          control={
            <Switch
              checked={notificationsEnabled}
              onChange={handleNotificationChange}
            />
          }
          label="Enable Notifications"
          sx={{
            display: "flex",
            alignItems: "center",
            "& .MuiFormControlLabel-label": {
              flex: 1,
              ml: 2,
            },
          }}
        />
      </Box>
    </StyledCard>
  );
};

export default NotificationSettings;

