import React, { useState, useRef, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent,Button,Alert } from '@mui/material';
import icons from '../Icons';
import { type } from '@testing-library/user-event/dist/type';

const RecordingModal = ({handleUploadScreenRecording,setIsOpen,isOpen}) => {
  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [recordingType, setRecordingType] = useState(null);
  const [countdown, setCountdown] = useState(null);
  const [duration, setDuration] = useState(0);
  const [error, setError] = useState(null);
  const [preview, setPreview] = useState(null);
  const [capturedImage, setCapturedImage] = useState(null);
  const [status, setStatus] = useState('idle');

  const mediaRecorderRef = useRef(null);
  const streamRef = useRef(null);
  const timerRef = useRef(null);
  const chunksRef = useRef([]);
  const recordOptions = [
    {
      icon: icons.Monitor,
      title: "Record screen",
      type:"screen",
      description: "Share your screen with audio",
    },
    {
      icon: icons.Camera,
      title: "Record camera",
      type:"camera",
      description: "Record from your camera with audio",
    },
    {
      icon: icons.Video,
      title: "Record screen & camera",
      type:"both",
      description: "Record your screen and camera simultaneously",
    },
    {
      icon: icons.Image,
      title: "Capture Image",
      type:"image",
      description: "Take a snapshot of your camera",
    },
  ];
  useEffect(() => {
    return () => {
      stopAllStreams();
      clearInterval(timerRef.current);
    };
  }, []);

  const formatDuration = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  };

  const stopAllStreams = () => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop());
    }
  };

  const resetState = () => {
    setIsRecording(false);
    setIsPaused(false);
    setDuration(0);
    setError(null);
    setPreview(null);
    setCapturedImage(null);
    setStatus('idle');
    chunksRef.current = [];
    clearInterval(timerRef.current);
    stopAllStreams();
  };

  const startRecording = async (type) => {
    try {
      resetState();
      setStatus('preparing');
      setRecordingType(type);
      const streams = [];
      
      if (type === 'screen' || type === 'both') {
        const screenStream = await navigator.mediaDevices.getDisplayMedia({
          video: { 
            cursor: "always",
            displaySurface: "monitor",
            frameRate: 30
          },
          audio: true
        });
        streams.push(screenStream);
      }

      if (type === 'camera' || type === 'both') {
        const cameraStream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true
        });
        streams.push(cameraStream);
      }

      let finalStream;
      if (streams.length > 1) {
        const tracks = streams.flatMap(stream => stream.getTracks());
        finalStream = new MediaStream(tracks);
      } else {
        finalStream = streams[0];
      }

      streamRef.current = finalStream;

      // Start countdown
      let count = 3;
      setCountdown(count);
      const countdownInterval = setInterval(() => {
        count--;
        if (count > 0) {
          setCountdown(count);
        } else {
          clearInterval(countdownInterval);
          setCountdown(null);
          beginRecording(finalStream);
        }
      }, 1000);

    } catch (error) {
      console.error('Error accessing media devices:', error);
      setError('Failed to access recording devices. Please check permissions.');
      setStatus('idle');
    }
  };

  const beginRecording = (stream) => {
    try {
      const mediaRecorder = new MediaRecorder(stream, {
        mimeType: 'video/webm;codecs=vp8,opus',
        videoBitsPerSecond: 2500000
      });

      mediaRecorder.ondataavailable = (e) => {
        if (e.data.size > 0) {
          chunksRef.current.push(e.data);
        }
      };

      mediaRecorder.onstop = async () => {
        setStatus('processing');
        await processRecording();
      };

      mediaRecorder.start(1000);
      mediaRecorderRef.current = mediaRecorder;
      setIsRecording(true);
      setStatus('recording');

      // Start duration timer
      timerRef.current = setInterval(() => {
        setDuration(prev => prev + 1);
      }, 1000);
    } catch (error) {
      console.error('Error starting recording:', error);
      setError('Failed to start recording. Please try again.');
      setStatus('idle');
    }
  };

  const processRecording = async () => {
    try {
      const blob = new Blob(chunksRef.current, { type: 'video/webm' });
      const previewUrl = URL.createObjectURL(blob);
      setPreview({ url: previewUrl, blob });
      setStatus('preview');
    } catch (error) {
      console.error('Error processing recording:', error);
      setError('Failed to process recording. Please try again.');
      setStatus('idle');
    }
  };

  const pauseRecording = () => {
    if (mediaRecorderRef.current && !isPaused) {
      mediaRecorderRef.current.pause();
      setIsPaused(true);
      clearInterval(timerRef.current);
    }
  };

  const resumeRecording = () => {
    if (mediaRecorderRef.current && isPaused) {
      mediaRecorderRef.current.resume();
      setIsPaused(false);
      timerRef.current = setInterval(() => {
        setDuration(prev => prev + 1);
      }, 1000);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      stopAllStreams();
      clearInterval(timerRef.current);
      setIsRecording(false);
    }
  };

  const downloadRecording = () => {
    if (preview?.blob) {
      const url = window.URL.createObjectURL(preview.blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `recording-${new Date().toISOString()}.webm`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }
  };

  const handleUploadRecording = () => {
    setIsOpen(false);
    console.log('Uploading recording...');
  };

  const captureImage = async () => {
    try {
      const canvas = document.createElement('canvas');
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(document.body, 0, 0, canvas.width, canvas.height);
      const imageData = canvas.toDataURL('image/png');
      return imageData;
    } catch (error) {
      console.error('Error capturing image:', error);
      throw error;
    }
  };

  const RecordOption = ({ icon: Icon, title, type,description }) => (
    <button className="w-full p-6 mb-3 bg-gray-50 rounded-lg 
                     flex flex-col items-center text-center gap-3
                     font-['Inter']
                     shadow-[5px_5px_10px_#d1d1d1,-5px_-5px_10px_#ffffff]
                     hover:shadow-[12px_12px_24px_#d1d1d1,-12px_-12px_24px_#ffffff]
                     hover:bg-white hover:scale-[1.02]
                     active:shadow-[inset_5px_5px_10px_#d1d1d1,inset_-5px_-5px_10px_#ffffff]
                     active:scale-[0.98]
                     transition-all duration-300 ease-in-out
                     hover:relative hover:z-10
                     hover:before:absolute hover:before:inset-0
                     hover:before:bg-gradient-to-b hover:before:from-white/50 hover:before:to-transparent
                     hover:before:rounded-lg hover:before:opacity-60" onClick={() => startRecording(type)}>
      <Icon className="w-8 h-8 text-gray-600" />
      <div className="space-y-1">
        <h3 className="text-lg text-gray-700 font-medium">{title}</h3>
        <p className="text-gray-500 text-sm">{description}</p>
      </div>
    </button>
  );

  const renderContent = () => {
    switch (status) {
      case 'idle':
        return (
          <div className="fixed inset-0 bg-black/50 flex items-center justify-center font-['Inter']">
            <div className="bg-gray-50 rounded-3xl p-8 max-w-xl w-full mx-4">
              <h2 className="text-2xl font-semibold mb-8 text-gray-700 text-center">
                Record your screen or camera
              </h2>
              
              <div className="space-y-4">
                {/* Render RecordOption components using map */}
                {recordOptions.map((option, index) => (
                  <RecordOption 
                    key={index}
                    icon={option.icon}
                    title={option.title}
                    description={option.description}
                  />
                ))}
              </div>
            </div>
          </div>
        );

      case 'recording':
        return (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <icons.Clock className="animate-pulse" />
              <span>{formatDuration(duration)}</span>
            </div>
            <div style={{ display: 'flex', gap: '8px', marginTop: '16px' }}>
              {isPaused ? (
                <Button onClick={resumeRecording} variant="outlined" startIcon={<icons.Play />}>
                  Resume
                </Button>
              ) : (
                <Button onClick={pauseRecording} variant="outlined" startIcon={<icons.Pause />}>
                  Pause
                </Button>
              )}
              <Button onClick={stopRecording} color="error" startIcon={<icons.X />}>
                Stop
              </Button>
            </div>
          </div>
        );

      case 'preview':
        return (
          <div>
            <video src={preview?.url} controls style={{ width: '100%', borderRadius: '8px' }} />
            <div style={{ display: 'flex', gap: '8px', marginTop: '16px', justifyContent: 'center' }}>
              <Button onClick={downloadRecording} variant="outlined" startIcon={<icons.Download />}>
                Download
              </Button>
              <Button onClick={handleUploadScreenRecording(preview)} variant="outlined" startIcon={<icons.Upload />}>
                Upload
              </Button>
              <Button
                onClick={() => {
                  resetState();
                  setIsOpen(false);
                }}
                variant="outlined"
                startIcon={<icons.X />}
              >
                Cancel
              </Button>
            </div>
          </div>
        );

      case 'processing':
        return (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '24px' }}>
            <div className="animate-spin" style={{ width: '32px', height: '32px', borderWidth: '4px', borderRadius: '50%' }} />
            <span>Processing recording...</span>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>Record your screen or camera</DialogTitle>
        <DialogContent>
          {error && (
            <Alert severity="error" style={{ marginBottom: '16px' }}>
              {error}
            </Alert>
          )}
          {countdown !== null ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '24px' }}>
              <span style={{ fontSize: '48px', fontWeight: 'bold' }}>{countdown}</span>
            </div>
          ) : (
            renderContent()
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RecordingModal;