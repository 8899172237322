import React from "react";
import { Box, Typography, TextField, Button, Grid, Avatar } from "@mui/material";
import { Lock } from 'lucide-react';
import StyledCard from "./StyledCard";
import SectionTitle from "./SectionTitle";
import useAuthUserStore from "../../../Store/useAuthUserStore";

const AccountSettings = ({ isMobile }) => {
  const { user } = useAuthUserStore();
  const [firstName, lastName] = user.name.split(' ');
  return (
    <StyledCard>
      <Box sx={{ p: 3 }}>
        <SectionTitle icon={<Lock />} title="Account Settings" />
        <Typography variant="body2" sx={{ mb: 4, color: "#6B7280" }}>
          Manage your account information
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="First name"
              variant="outlined"
              defaultValue={firstName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Last name"
              variant="outlined"
              defaultValue={lastName}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Email address"
              variant="outlined"
              defaultValue={user.email}
            />
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: isMobile ? "column" : "row",
              }}
            >
              <Avatar
                sx={{
                  width: 64,
                  height: 64,
                  mr: isMobile ? 0 : 2,
                  mb: isMobile ? 2 : 0,
                }}
                src={user.profile_pic}
              />
              <Button variant="outlined">Change avatar</Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </StyledCard>
  );
};

export default AccountSettings;

