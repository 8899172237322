import React, { useState } from "react";
import { Box, Typography, FormControl, MenuItem } from "@mui/material";
import { User, ChevronDown } from 'lucide-react';
import StyledCard from "./StyledCard";
import StyledSelect from "./StyledSelect";
import SectionTitle from "./SectionTitle";

const GeneralSettings = () => {
  const [theme, setTheme] = useState("dark");
  const [language, setLanguage] = useState("english");

  return (
    <StyledCard>
      <Box sx={{ p: 3 }}>
        <SectionTitle icon={<User />} title="General Settings" />
        <Typography variant="body2" sx={{ mb: 4, color: "#6B7280" }}>
          Manage your general preferences
        </Typography>

        <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ color: "#111827", fontWeight: 500 }}>
              Theme
            </Typography>
            <FormControl sx={{ width: "200px" }}>
              <StyledSelect
                value={theme}
                onChange={(e) => setTheme(e.target.value)}
                IconComponent={ChevronDown}
              >
                <MenuItem value="light">Light</MenuItem>
                <MenuItem value="dark">Dark</MenuItem>
                <MenuItem value="system">System</MenuItem>
              </StyledSelect>
            </FormControl>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ color: "#111827", fontWeight: 500 }}>
              Language
            </Typography>
            <FormControl sx={{ width: "200px" }}>
              <StyledSelect
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
                IconComponent={ChevronDown}
              >
                <MenuItem value="english">English</MenuItem>
                <MenuItem value="spanish">Spanish</MenuItem>
                <MenuItem value="french">French</MenuItem>
                <MenuItem value="german">German</MenuItem>
              </StyledSelect>
            </FormControl>
          </Box>
        </Box>
      </Box>
    </StyledCard>
  );
};

export default GeneralSettings;

